import React from 'react';
import { getAuth, signOut } from 'firebase/auth';

const AdminHeader = () => {


  
  const handleLogout = () => {
    const auth = getAuth();

    // Sign out the user
    signOut(auth)
      .then(() => {
        window.location.href = '/admin';
      })
      .catch((error) => {
        console.error('Logout failed:', error);
      });
  };

  return (
    <header className="flex items-center justify-between bg-white px-6 py-3 shadow">
      <h1 className="text-xl font-bold text-gray-800">Splode Events Admin Panel</h1>
      <button
        onClick={()=>handleLogout()}
        className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
      >
        Logout
      </button>
    </header>
  );
};

export default AdminHeader;
