// yourAzureUploadModule.js

const azureStorageUrl = 'https://mobshalebbcc.blob.core.windows.net';
// 'https://mobshalebbcc.blob.core.windows.net/trishul-events?sp=racwdli&st=2023-11-27T17:46:38Z&se=2023-12-28T01:46:38Z&spr=https&sv=2022-11-02&sr=c&sig=ym2VoXT9CB3QkBAtwo%2Bh3r%2FyUdOgVwFikH%2FM0xnl5o8%3D'; // Replace with your Azure Storage URL
const sasToken = '?sp=racwdli&st=2023-11-27T17:46:38Z&se=2023-12-28T01:46:38Z&spr=https&sv=2022-11-02&sr=c&sig=ym2VoXT9CB3QkBAtwo%2Bh3r%2FyUdOgVwFikH%2FM0xnl5o8%3D'; // Replace with your SAS token

async function uploadImageToAzure(selectedFile) {
    console.log('Hi')
    console.log(selectedFile)
    const uploadUrl = `${azureStorageUrl}/trishul-events/${selectedFile.name}${sasToken}`;
    console.log(uploadUrl)

    try {
        const response = await fetch(uploadUrl, {
            method: 'PUT',
            body: selectedFile,
            headers: {
                'Content-Type': selectedFile.type,
                'x-ms-blob-type': 'BlockBlob', // Add this header
            },
        });

        if (response.ok) {
            console.log(response);
            console.log('Image uploaded successfully!');
            // You can perform additional logic, such as updating Salesforce records with Azure Storage URL
        } else {
            console.error('Failed to upload image:', response.statusText);
            // Handle error appropriately, e.g., show an error message to the user
        }
    } catch (error) {
        console.error('Error during image upload:', error.message);
        // Handle error appropriately
    }
}

export { uploadImageToAzure };
