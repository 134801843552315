import { getAuth, onAuthStateChanged } from 'firebase/auth';

 const  checkAuth = () => {
    return new Promise((resolve, reject) => {
      const auth = getAuth();
  
      const unsubscribe = onAuthStateChanged(auth, (user) => {
        unsubscribe(); // Stop listening for changes once initial state is received
  
        if (user) {
          // User is logged in
          resolve(user);
        } else {
          // User is not logged in
          reject('User not logged in');
        }
      }, (error) => {
        // An error occurred while checking auth state
        reject(error.message);
      });
    });
  };
  

  export default checkAuth;