import { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from "../firebase/firebaseConfig";
import { getFirestore, doc, setDoc, getDoc, collection } from 'firebase/firestore';

const firestore = getFirestore();

const AddNewAddress = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [alternativeNumber, setAlternativeNumber] = useState('');
  const [pincode, setPincode] = useState('');
  const [address, setAddress] = useState('');
  const [locality, setLocality] = useState('');
  const [city, setCity] = useState('Bangalore');
  const [state, setState] = useState('Karnataka');
  const [addressType, setAddressType] = useState('Home');
  const [user, loading] = useAuthState(auth);
  const [isProcessing, setIsProcessing] = useState(false);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (id) {
      const fetchUserAddress = async () => {
        try {
          const docRef = doc(firestore, 'users', user.uid, 'addresses', id);
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            const add = docSnap.data();
            setCity(add.city);
            setMobileNumber(add.mobileNumber);
            setName(add.name);
            setPincode(add.pincode);
            setAddress(add.address);
            setLocality(add.locality);
          } else {
            console.log('No such document!');
          }
        } catch (error) {
          console.error('Failed to fetch user address:', error);
        }
      };

      fetchUserAddress();
    }
  }, [id, user?.uid]);

  const validateForm = () => {
    const newErrors = {};
    if (!name) newErrors.name = 'Name is required';
    if (!mobileNumber || !/^\d{10}$/.test(mobileNumber)) {
      newErrors.mobileNumber = 'Enter a valid 10-digit mobile number';
    }
    if (alternativeNumber && !/^\d{10}$/.test(alternativeNumber)) {
      newErrors.alternativeNumber = 'Alternative number must be a 10-digit number';
    }
    if (!pincode || pincode.length !== 6 || isNaN(pincode)) {
      newErrors.pincode = 'Enter a valid 6-digit pincode';
    }
    if (!address) newErrors.address = 'Address is required';
    if (!locality) newErrors.locality = 'Locality is required';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleProceedToBook = async () => {
    if (!validateForm()) return;

    setIsProcessing(true);
    try {
      const addressData = {
        name,
        mobileNumber,
        alternativeNumber,
        address,
        locality,
        city,
        state,
        pincode,
        addressType,
        timestamp: new Date().toISOString(),
      };
  
      if (id) {
        await setDoc(doc(firestore, 'users', user.uid, 'addresses', id), addressData);
        console.log('Address updated successfully');
      } else {
        const addressRef = doc(collection(firestore, 'users', user.uid, 'addresses'));
        await setDoc(addressRef, addressData);
        console.log('Address added successfully');
      }
  
      navigate(-1);
    } catch (error) {
      console.error('Failed to add/update address:', error);
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <div className="bg-white">
      <div className="bg-white">
        <nav className="sticky top-0 z-40 border-gray-600 bg-white shadow">
          <div className="flex flex-wrap justify-between items-center mx-auto max-w-screen-xl p-2">
            <div className="flex items-center md:w-auto w-full">
              <div className="flex items-center">
                <button onClick={() => navigate(-1)} className="focus:outline-none cursor-pointer">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6 text-black"
                    viewBox="0 0 21 22"
                  >
                    <path
                      fill="currentColor"
                      d="M.27 9.992a1.289 1.289 0 0 0-.266.42L0 10.424l.004-.01c.061-.156.151-.3.267-.421Zm10.82-7.578-7.534 7.51h16.032a.989.989 0 0 1 0 1.977H3.555l7.536 7.512a.989.989 0 0 1 0 1.404l-.004.004h-.001a.988.988 0 0 1-1.404 0L.486 11.625a.988.988 0 0 1-.202-.32l-.007-.016a.988.988 0 0 1 0-.75l.007-.017a.989.989 0 0 1 .202-.32L9.68 1.006a.996.996 0 1 1 1.408 1.408Z"
                    ></path>
                  </svg>
                </button>
                <Link to="/cart" className="ml-4 flex font-bold items-center">Add Address</Link>
              </div>
            </div>
          </div>
        </nav>

        <div className="bg-gray-100 p-4">
          <div className="mb-4">
            <div className="px-4 py-2 bg-gray-300 font-semibold">Contact Details</div>
            <input
              type="text"
              placeholder="Name"
              className="w-full border p-2 rounded-md"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            {errors.name && <p className="text-red-500 text-xs">{errors.name}</p>}
            <input
              type="text"
              placeholder="Mobile Number"
              className="w-full mt-2 border p-2 rounded-md"
              value={mobileNumber}
              onChange={(e) => setMobileNumber(e.target.value)}
            />
            {errors.mobileNumber && <p className="text-red-500 text-xs">{errors.mobileNumber}</p>}
            <input
              type="text"
              placeholder="Alternative Number"
              className="w-full mt-2 border p-2 rounded-md"
              value={alternativeNumber}
              onChange={(e) => setAlternativeNumber(e.target.value)}
            />
            {errors.alternativeNumber && <p className="text-red-500 text-xs">{errors.alternativeNumber}</p>}
          </div>

          <div className="mb-4">
            <div className="px-4 py-2 bg-gray-300 font-semibold">Address</div>
            <input
              type="text"
              placeholder="Pincode"
              className="w-full mt-2 border p-2 rounded-md"
              value={pincode}
              onChange={(e) => setPincode(e.target.value)}
            />
            {errors.pincode && <p className="text-red-500 text-xs">{errors.pincode}</p>}
            <input
              type="text"
              placeholder="Address"
              className="w-full mt-2 border p-2 rounded-md"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />
            {errors.address && <p className="text-red-500 text-xs">{errors.address}</p>}
            <input
              type="text"
              placeholder="Locality/Town"
              className="w-full mt-2 border p-2 rounded-md"
              value={locality}
              onChange={(e) => setLocality(e.target.value)}
            />
            {errors.locality && <p className="text-red-500 text-xs">{errors.locality}</p>}
            <input
              disabled
              type="text"
              placeholder="City"
              className="w-full mt-2 border p-2 rounded-md"
              value={city}
            />
            <input
              disabled
              type="text"
              placeholder="State"
              className="w-full mt-2 border p-2 rounded-md"
              value={state}
            />
          </div>
        </div>

        <div className="mb-4">
          <div className="px-4 py-2 bg-gray-300 font-semibold">Address Type</div>
          <select
            className="w-full border p-2 rounded-md mt-2"
            value={addressType}
            onChange={(e) => setAddressType(e.target.value)}
          >
            <option value="Home">Home</option>
            <option value="Office">Office</option>
          </select>
        </div>
      </div>

      <div className="z-40 border-t mt-3 bg-white py-1 bottom-0 sticky px-2 flex">
        <button
          onClick={handleProceedToBook}
          className={`w-full p-2 text-white rounded justify-center bg-black ${isProcessing ? 'cursor-not-allowed' : 'cursor-pointer'}`}
          disabled={isProcessing}
        >
          {isProcessing ? 'Saving...' : 'Proceed To Book'}
        </button>
      </div>
    </div>
  );
};

export default AddNewAddress;

