import React, { useState, useEffect } from 'react';

const ErrorNotification = ({ errorMessage, show,setShow }) => {

  const handleClose = () => {
    setShow(false);
  };

  useEffect(() => {
    if (show) {
      const timer = setTimeout(() => {
        setShow(false);
      }, 1000); // Set the timeout duration (1 second in this case)

      return () => clearTimeout(timer); // Clear the timeout on component unmount
    }
  }, [show]);

  return (
    <>
      {show && (
        <div style={{ width: "150px" }} className="fixed top-10 left-1/2 transform -translate-x-1/2 bg-red-500 text-white p-2 rounded-md">

          <p className="text-xs sm:text-sm">{errorMessage}</p>
          <button
            className="float-right text-white text-xs sm:text-sm"
            onClick={handleClose}
          >
            
          </button>
        </div>
      )}
    </>
  );
};

export default ErrorNotification;
