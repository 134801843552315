import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { getFirestore, collection,addDoc,doc, setDoc, getDoc } from 'firebase/firestore';
import Header from './Components/Header';
import ProductImageSlider from './Components/ProductPage/ProductImageSlider';
import HorizontalProductsView from './Components/common/HorizontalDecors';
import axios from "axios";
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from "./firebase/firebaseConfig";
import { useNavigate } from 'react-router-dom';
import AuthSlide from './Components/BottomSlide';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DateTimePicker from 'react-datetime-picker';
import DateTimeChange from './Components/DateTimeChange';
const SFURL = process.env.REACT_APP_SFSERVER;

const ProductPage = () => { 
  const { productId } = useParams();
  const [productData, setProductData] = useState(null);
  const [images, setImages] = useState([]);
  const [description, setDescription] = useState([]);
  const [user, loading] = useAuthState(auth);
  const [zipCode, setZipCode] = useState("");
  const [isValid, setIsValid] = useState(false);
  const navigate = useNavigate();
  const [checked, setChecked] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  const [dateTime, setDateTime] = useState(null);
  const [isDateTimeModalOpen, setIsDateTimeModalOpen] = useState(false);

  const firestore = getFirestore(); // Initialize Firestore


  const handleZipCodeChange = (event) => {
    const newZipCode = event.target.value;
    
      setZipCode(newZipCode);
     
        setChecked(false);

  };

  const checkZipCode = () => {
    setChecked(true);
    if (zipCode.startsWith("560") && zipCode.length === 6) {
      setIsValid(true);
      console.log("Valid ZIP code");
      // Perform additional actions for a valid ZIP code
    } else if(zipCode.length === 6) {
      setIsValid(false);
      console.log("Invalid ZIP code");
      // Display an error message or perform other actions for an invalid ZIP code
    }
  };


  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchProductData = async () => {
      const db = getFirestore();
      const productRef = doc(db, 'products', productId);
      const productSnap = await getDoc(productRef);

      if (productSnap.exists()) {
        const data = productSnap.data();
        console.log(data);
        const product = {
          id: productId,
          category: data.category,
          price: data.price,
          originalPrice: data.originalPrice,
          name: data.name,
          description: data.description ,
          imageUrl: [data.downloadUrls[0]]
        };

        setDescription(product.description);
        setImages(product.imageUrl);
        setProductData(product);
      } else {
        console.log("No such document!");
      }
    };

    fetchProductData();
  }, [productId]);



  const handleBooking = async () => {
    const firestore = getFirestore(); // Get Firestore instance

    // Format date for Firestore
    const formattedDateSF = dateTime;

    if (!user) {
        setShowLogin(true);
        return;
    }

    setIsProcessing(true);

    const bookingData = {
        decorationId: productId,
        dateTime: formattedDateSF,
        createdAt: new Date(),
    };

    // Generate a custom document ID (e.g., 'cart123')
    const customCartId = 'cart123'; // Update if dynamic ID generation is needed

    try {
        // Reference to the user's cart collection
        const cartDocRef = doc(firestore, 'users', user.uid, 'cart', customCartId);

        // Add a new document with the custom ID
        await setDoc(cartDocRef, bookingData);

        console.log('Booking added to user cart successfully');
        navigate('/address-list'); // Navigate to address list after success
    } catch (error) {
        console.error('Error adding booking to cart:', error.message);
    } finally {
        setIsProcessing(false);
    }
  };
  
  if (!productData) {
    return (
      <div className="animate-pulse">
        <div className="bg-gray-200 h-full">
          <Header back={true} />
  
          <div className="bg-white p-4">
            {/* Big Image Placeholder */}
            <div className="animate-pulse bg-gray-300 h-64 w-full mb-4"></div>
  
            {/* Product Name Placeholder */}
            <h1 className="animate-pulse bg-gray-300 h-8 w-2/3 mb-4"></h1>
  
            {/* Price and Discount Placeholder */}
            <div className="flex mt-1 text-sm">
              <p className="animate-pulse bg-gray-300 h-4 w-1/4"></p>
              <p className="animate-pulse bg-gray-300 h-4 w-1/4 ml-2"></p>
              <p className="animate-pulse bg-gray-300 h-4 w-1/4 ml-2"></p>
              <p className="animate-pulse bg-gray-300 h-4 w-1/4 ml-2"></p>
            </div>
  
            {/* Zip Code Check Placeholder */}
            <div className="border border-gray-500 mt-2 flex">
              <input
                maxLength={6}
                className="animate-pulse focus:outline-none text-black text-sm font-semibold px-3 py-2 w-1/2"
                placeholder="Enter a PIN code"
              />
              <button className="animate-pulse bg-black text-white px-3 py-2 ml-auto"></button>
            </div>
  
            {/* Availability Placeholder */}
            <div className="flex p-3 space-x-2 items-center">
              <div className="animate-pulse bg-gray-300 h-4 w-1/4"></div>
              <p className="animate-pulse bg-gray-300 h-4 w-3/4 ml-2"></p>
            </div>
  
            {/* Policy Details Placeholder */}
            <div className="text-gray-400 mt-5 text-sm">
              <p className="animate-pulse bg-gray-300 h-4 w-3/4"></p>
              <p className="animate-pulse bg-gray-300 h-4 w-3/4 mt-2"></p>
              <p className="animate-pulse bg-gray-300 h-4 w-3/4 mt-2"></p>
            </div>


            <div className="flex p-3 space-x-2 items-center">
              <div className="animate-pulse bg-gray-300 h-4 w-1/4"></div>
              <p className="animate-pulse bg-gray-300 h-4 w-3/4 ml-2"></p>
            </div>

            <div className="text-gray-400 mt-5 text-sm">
              <p className="animate-pulse bg-gray-300 h-4 w-3/4"></p>
              <p className="animate-pulse bg-gray-300 h-4 w-3/4 mt-2"></p>
              <p className="animate-pulse bg-gray-300 h-4 w-3/4 mt-2"></p>
            </div>


            <div className="flex p-3 space-x-2 items-center">
              <div className="animate-pulse bg-gray-300 h-4 w-1/4"></div>
              <p className="animate-pulse bg-gray-300 h-4 w-3/4 ml-2"></p>
            </div>

            <div className="text-gray-400 mt-5 text-sm">
              <p className="animate-pulse bg-gray-300 h-4 w-3/4"></p>
              <p className="animate-pulse bg-gray-300 h-4 w-3/4 mt-2"></p>
              <p className="animate-pulse bg-gray-300 h-4 w-3/4 mt-2"></p>
            </div>



            <div className="flex p-3 space-x-2 items-center">
              <div className="animate-pulse bg-gray-300 h-4 w-1/4"></div>
              <p className="animate-pulse bg-gray-300 h-4 w-3/4 ml-2"></p>
            </div>

            <div className="text-gray-400 mt-5 text-sm">
              <p className="animate-pulse bg-gray-300 h-4 w-3/4"></p>
              <p className="animate-pulse bg-gray-300 h-4 w-3/4 mt-2"></p>
              <p className="animate-pulse bg-gray-300 h-4 w-3/4 mt-2"></p>
            </div>

          </div>
        </div>
      </div>
    );
  }


  
  const handleDateTimeChange = (newDateTimeString) => {
    setDateTime(new Date(newDateTimeString));
  };

  const toggleDateTimeModal = () => {
    setIsDateTimeModalOpen(!isDateTimeModalOpen);
  };

  const isDateTimeSelected = dateTime !== null;


  return (
    < div className="bg-gray-200">

<AuthSlide isOpen={showLogin} setAuthSlide={setShowLogin}/>

      <Header back={true} />

      <div className=" bg-gray-100  h-full">
        <div className="bg-white">
          <ProductImageSlider images={images} />


          <div className="mt-2 pb-3 bg-white px-4">
            <h1>
              {productData.name}
            </h1>
            <div className="flex mt-1 text-sm">
              <p className=" pr-1 font-semibold">
                ₹{productData.price}
              </p>
              <p className="pr-1 text-gray-400 ">
                MRP
              </p>
              <p className="pr-1 text-gray-400 line-through">
                ₹{productData.originalPrice}
              </p>
              <p className=" text-orange-400 font-semibold">
                ({(((productData.originalPrice - productData.price) / productData.originalPrice) * 100).toFixed(2)}%)
              </p>
            </div>
          </div>


        </div>





        <div className="bg-white  p-2 mt-4">
          <p className="text-black text-sm font-semibold mt-3 ml-4">CHECK AVALIBILITY & SERVICE</p>
          <div className="border border-gray-500 mr-4 ml-4 mt-2 flex">
      <input
        maxLength={6}
        className="focus:outline-none text-black text-sm font-semibold px-3 py-2"
        placeholder="Enter a PIN code"
        value={zipCode}
        onChange={handleZipCodeChange}
      />
      <button
        className="bg-black text-white px-3 py-2 ml-auto"
        onClick={() =>checkZipCode()}
      >
        CHECK
      </button>
    </div>
          <div className="flex p-3 space-x-2 items-center">
 
<>
{checked && (
  <>
    {isValid && checked ? (
      <>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="text-green"
          viewBox="0 0 128 128"
          width="18px"
          height="18px"
          fill="green"
        >
          <path d="M 64 6 C 32 6 6 32 6 64 C 6 96 32 122 64 122 C 96 122 122 96 122 64 C 122 32 96 6 64 6 z M 64 12 C 92.7 12 116 35.3 116 64 C 116 92.7 92.7 116 64 116 C 35.3 116 12 92.7 12 64 C 12 35.3 35.3 12 64 12 z M 85.037109 48.949219 C 84.274609 48.974219 83.500391 49.300391 82.900391 49.900391 L 62 71.599609 L 51.099609 59.900391 C 49.999609 58.700391 48.100391 58.599219 46.900391 59.699219 C 45.700391 60.799219 45.599219 62.700391 46.699219 63.900391 L 59.800781 78 C 60.400781 78.6 61.1 79 62 79 C 62.8 79 63.599219 78.699609 64.199219 78.099609 L 87.199219 54 C 88.299219 52.8 88.299609 50.900781 87.099609 49.800781 C 86.549609 49.200781 85.799609 48.924219 85.037109 48.949219 z" />
        </svg>

        <p className="text-sm text-green">
          Our service is available at this pin code {zipCode}
        </p>
      </>
    ) : (
      <>
        <p className="ml-2 text-red-500">
          Service Not available at the ZIP code
        </p>
      </>
    )}
  </>
)}


</>


 
  </div>


          <div className=" text-gray-400 mt-5 ml-4 text-sm ">
            <p>Pay 50% on Service Day Available</p>
            <p>Easy Cancel Event before 48-hours</p>
            <p>Complete Refund of money on cancel</p>
          </div>
        </div>


        <div className="bg-white p-2 mt-4">
          <p className="text-black text-sm font-semibold mt-3 ml-4">Decoration Description</p>
          <div className="text-gray-400 mt-5 ml-4 text-sm">
            {description.map((p, index) => (
              <p key={index}>{p}</p>
            ))}
          </div>


        </div>

        <div className='bg-white'>
      <p className="text-black text-sm font-semibold mt-3 ml-4">Select date and time</p>

      <div className="border bg-white border-gray-500 mr-4 ml-4 mt-2 flex">
        <button onClick={toggleDateTimeModal} className="border border-gray-300 px-2 py-1 rounded w-full">
          {dateTime ? `${formatDate(dateTime)} ${formatTime(dateTime)}` : 'Select date and time'}
        </button>
      </div>

      {!isDateTimeSelected && (
        <p className="text-red-500 ml-4 mt-1">Please select date and time</p>
      )}

      <div className="z-40 border-t mt-3 bg-white py-1 bottom-0 sticky px-2 flex">
        <button
          onClick={() => handleBooking()}
          className={`w-full p-2 text-white rounded justify-center bg-${isProcessing ? 'black' : 'black'} ${isProcessing || !isDateTimeSelected ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'}`}
          disabled={isProcessing || !isDateTimeSelected}
        >
          {isProcessing ? 'Processing...' : 'Book Decoration'}
        </button>
      </div>

      {/* Render DateTimeChange modal */}
      <DateTimeChange
        currentDateTime={dateTime}
        onDateTimeChange={handleDateTimeChange}
        isOpen={isDateTimeModalOpen}
        onClose={toggleDateTimeModal}
      />
    </div>
       


        <div className="bg-white  p-2 mt-4">
          <p className="text-black text-sm font-semibold mt-3 ml-4"> Ratings & Reviews</p>
          <div className=" text-gray-400 mt-5 ml-4 text-sm ">
            <p>This is a new decor we don't have reviews yet!</p>
          </div>
        </div>

        <HorizontalProductsView category={productData.category} />

        
              



      </div>



    </div>
  )
}


export default ProductPage



// Helper function to format date
function formatDate(date) {
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
}

function formatTime(time) {
  const hours = time.getHours();
  const minutes = String(time.getMinutes()).padStart(2, '0');
  const ampm = hours >= 12 ? 'PM' : 'AM';
  const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
  return `${formattedHours}:${minutes} ${ampm}`;
}