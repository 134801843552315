import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap-button-loader';
import { GoogleLoginButton } from '../Components/common/GoogleBtn';
import { getAuth, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { uploadImageToAzure } from './test';


const AdminLogin = () => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading,setLoading] = useState(false);
  const  [selectedFile, setSelectedFile]  = useState();


  useEffect(() => {
    // Check if the user is already logged in
    const auth = getAuth();
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        if(user.email == "manuvini29@gmail.com"){
          setLoggedIn(true);
        }
      }
    });

    // Cleanup the auth state change listener
    return () => unsubscribe();
  }, []);



  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
}

  const handleLogin = () => {
    console.log("Hello");
    const auth = getAuth();
    const provider = new GoogleAuthProvider();

    // Sign in with Google popup
    signInWithPopup(auth, provider)
      .then((result) => {
        const user = result.user;
        if (user.email === "manuvini29@gmail.com") {
          // Redirect to admin home after successful login
          window.location.href = '/admin/home';
        } else {
          // Show an error message for unauthorized access
          setError('You are not authorized to access the admin panel.');

        }
        // Redirect to admin home after successful login
      })
      .catch((error) => {
        console.error('Login failed:', error);
      });
  };


 

  if (loggedIn) {
    window.location.href = '/admin/home';
  }

  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gray-100">
      <h2 className="text-2xl font-bold mb-4">Admin Login</h2>
    


      <div className="fixed inset-x-8 flex justify-center items-center">
        <div className="bg-white rounded-lg p-10 w-full max-w-md">
          <h1 className=" font-bold text-center mb-4 select-none">Admin Login</h1>
          <div className="h-0.5 bg-gray-300 my-4"></div>


          <input type="file" accept="image/*" onChange={handleFileChange} />
    <Button onClick={() => uploadImageToAzure(selectedFile) }>Upload Image</Button>

          {/* Additional login form elements go here */}
          <GoogleLoginButton onClick={handleLogin}  ></GoogleLoginButton>
        </div>
      </div>
          {error && <p className="text-red-500 mb-2">{error}</p>}

    </div>
  );
};

export default AdminLogin;
