import React, { useEffect, useState } from "react";
import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import OrderModal from "./Components/OrderModal";
import SidePanel from "./Components/SidePanel";
import AdminHeader from "./Components/Header";
import {
  getFirestore,
  collection,
  query,
  onSnapshot,
} from "firebase/firestore";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const Orders = () => {
  const [orders, setOrders] = useState([]);
  const [allOrder, setAllOrders] = useState([]);
  const [loggedIn, setLoggedIn] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [ordersPerPage] = useState(10);
  const [filterStatus, setFilterStatus] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [orderData, setOrderData] = useState(null);
  const [currentOrderId, setCurrentOrderId] = useState(null);

  const [productNameFilter, setProductNameFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [currentMonth, setCurrentMonth] = useState(
    String(new Date().getMonth() + 1).padStart(2, "0")
  );
  const [sales, setSales] = useState(null);

  
  useEffect(() => {
    const checkAdminStatus = (user) => {
      if (user && user.email === "manuvini29@gmail.com") {
        setLoggedIn(true);
      } else {
        window.location.href = "/admin";
      }
    };

    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      checkAdminStatus(user);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const firestoreDb = getFirestore();
    console.log(currentMonth);
    const ordersCollectionRef = collection(
      firestoreDb,
      "orders",
      currentYear.toString() + currentMonth,
      'order'
    );

    const q = query(ordersCollectionRef);

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const data = snapshot.docs.map((doc) => ({
        id: doc.id,
        orderYearMonth: currentYear.toString() + currentMonth,
        ...doc.data(),
      }));
      let sales = 0;
      data.forEach((d) => {
        if (d.status != "Canceled" || d.status != "Returned") {
          sales = Number(d.total) + sales;
        }
      });
      setSales(sales);
      console.log(data);
      setOrders(data);
      setAllOrders(data);
      handleFilterChange(statusFilter, data, currentPage); // Apply filter after data is fetched
    });

    return () => unsubscribe();
  }, [statusFilter, currentPage, currentYear, currentMonth]);

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setCurrentYear(date.getFullYear());
    setCurrentMonth(String(date.getMonth() + 1).padStart(2, "0"));
  };

  const priceFormat = (price) => {
    price = Number(price);
    return price.toLocaleString();
  };


 
  const handleFilterChange = (status, orders, currentPage) => {
    const filteredOrders = orders.filter((order) => {
      // Check if the order's status matches the selected status
      if (status) {
        return order.status === status;
      } else {
        return true;
      }
    });
    setOrders(filteredOrders);
    setCurrentPage(currentPage);
  };

  const generateThumbnailUrl = (imageUrl, width, height) => {
    
    const thumbnailUrl = `${imageUrl}?alt=media&width=${width}&height=${height}`;
    return thumbnailUrl;
  };

  const viewOrderDetails = (orderData) => {
    setOrderData(orderData);

    setCurrentOrderId()
        openModal();
  };

  // Function to open the modal
  const openModal = () => {
    setModalOpen(true);
  };

  // Function to close the modal
  const closeModal = () => {
    setModalOpen(false);
  };

  // Get current orders for pagination
  const indexOfLastOrder = currentPage * ordersPerPage;
  const indexOfFirstOrder = indexOfLastOrder - ordersPerPage;
  const currentOrders = orders.slice(indexOfFirstOrder, indexOfLastOrder);

  // Change page
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  if (!loggedIn) {
    return null; // or return a loading/spinner component
  }

  function formatDate(timestamp) {
    const date = new Date(timestamp);
  
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
  
    return `${day}/${month}/${year}`;
  }

  return (
    <div className="flex h-screen">
      <SidePanel />
      <div className="flex-1 ml-56">
        <AdminHeader />
        <div className="p-6">
          <h2 className="text-2xl font-bold mb-4">Orders</h2>
          <div className="flex justify-between mb-4">
            <div className="mb-4">
              <select
                value={statusFilter}
                onChange={(e) => setStatusFilter(e.target.value)}
                className="px-2 py-1 border rounded"
              >
                <option value="">All</option>
                <option value="pending">Pending</option>
                <option value="orderConfirmed">Order Confirmed</option>
                <option value="shipped">Shipped</option>
                <option value="Delivered">Delivered</option>
                <option value="Returned">Returned</option>
                <option value="Canceled">Canceled</option>
                <option value="success">Done</option>
              </select>
              <DatePicker
                selected={selectedDate}
                onChange={handleDateChange}
                dateFormat="MM/yyyy"
                showMonthYearPicker
                className="px-2 py-1 border rounded"
              />
              <button
                className="bg-blue-500 text-white px-4 py-2 rounded ml-2"
                onClick={() => handleFilterChange(statusFilter, allOrder, 1)}
              >
                Apply Filter
              </button>
            </div>

            <p className="px-4 py-2 rounded-md mr-2">
              Sales :- {priceFormat(sales)}
            </p>
          </div>

          {currentOrders.length > 0 ? (
            <>
              <table className="table-auto w-full bg-gray-100">
                <thead>
                  <tr>
                    <th className="px-6 py-3 bg-gray-200 text-gray-700 border">
                      Order Id
                    </th>
                    <th className="px-6 py-3 bg-gray-200 text-gray-700 border">
                      Customer Name
                    </th>
                    <th className="px-6 py-3 bg-gray-200 text-gray-700 border">
                      Date
                    </th>
                    <th className="px-6 py-3 bg-gray-200 text-gray-700 border">
                      Zip Code
                    </th>
                    <th className="px-6 py-3 bg-gray-200 text-gray-700 border">
                      Status
                    </th>
                    <th className="px-6 py-3 bg-gray-200 text-gray-700 border">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {currentOrders.map((order) => (
                    <tr key={order.id}>
                      <td className="px-6 py-3 border whitespace-no-wrap">
                        {order.orderNumber}
                      </td>
                      <td className="px-6 py-3 border whitespace-no-wrap">
                        {order.selectedAddress.name}
                      </td>
                      <td className="px-6 py-3 border whitespace-no-wrap">
                        {convertTimestampToDate(order.createdAt)}
                      </td>
                      <td className="px-6 py-3 border whitespace-no-wrap">
                        {order.selectedAddress.pincode}
                      </td>
                      <td className="px-6 py-3 border whitespace-no-wrap">
                        {order.orderStatus}
                      </td>
                      <td className="px-6 py-3 border whitespace-no-wrap flex items-center justify-center h-full">
                        <div className="flex">
                          <button
                            onClick={() => {
                              viewOrderDetails(order);
                            }}
                            className="bg-blue-500 hover:bg-blue-600 text-white px-6 py-3 border rounded"
                          >
                            View
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="mt-4">
                <Pagination
                  ordersPerPage={ordersPerPage}
                  totalOrders={orders.length}
                  paginate={paginate}
                  currentPage={currentPage}
                />
              </div>
            </>
          ) : (
            <p>No orders found.</p>
          )}
        </div>
      </div>
      {modalOpen && (
        <OrderModal orderData={orderData} closeModal={closeModal} />
      )}
    </div>
  );
};

const Pagination = ({ ordersPerPage, totalOrders, paginate, currentPage }) => {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalOrders / ordersPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <ul className="flex justify-center space-x-2">
      {pageNumbers.map((number) => (
        <li
          key={number}
          className={`${
            number === currentPage ? "text-blue-500 font-bold" : ""
          } cursor-pointer`}
          onClick={() => paginate(number)}
        >
          {number}
        </li>
      ))}
    </ul>
  );
};


function convertTimestampToDate(timestamp) {
  // Convert seconds and nanoseconds to milliseconds
  const milliseconds = timestamp.seconds * 1000 + timestamp.nanoseconds / 1e6;

  // Create a new Date object using the milliseconds
  const date = new Date(milliseconds);

  // Extract date components
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed, so add 1
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  // Format the date as "YYYY-MM-DD HH:mm:ss"
  const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

  return formattedDate;
}

export default Orders;
