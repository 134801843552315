import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

const Paymentpage = () => {
  const navigate = useNavigate();
  const delivery = 5565;
  const service = 7654;
  const cartItems = [1];

  const setIsMobileOpen = false; // It should be set to true or false as needed
  const handleLogout = () => {
    // Implement your logout logic here
  };
  const isLogedInOptions = false; // It should be set to true or false as needed
  const isDropdownVisible = false; // It should be set to true or false as needed
  const isLogedIn = false; // It should be set to true or false as needed

  const [isZipCodeChangeOpen, setIsZipCodeChangeOpen] = useState(false);
  const [zipCode, setZipCode] = useState("560058");

  const handleZipCodeChange = (newZipCode) => {
    setZipCode(newZipCode);
  };

  const [isDateTimeChangeOpen, setIsDateTimeChangeOpen] = useState(false);
  const [dateTime, setDateTime] = useState();
  const handleDateTimeChange = (newDateTime) => {
    const date = new Date(newDateTime);
    const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    const formattedTime = `${formattedHours}:${formattedMinutes} ${ampm}`;
  
    // Combine the formatted date and time
    const formattedDateTime = `${formattedDate} ${formattedTime}`;
  
    setDateTime(formattedDateTime);
  };
  

  return (
    <>
      <nav className="sticky top-0 z-40 border-gray-600 bg-white shadow">
        <div className="flex flex-wrap justify-between items-center mx-auto max-w-screen-xl p-2">
          <div className="flex items-center md:w-auto w-full">
            <div className="flex items-center">
              {true ? (
                <button
                  onClick={() => navigate(-1)}
                  className="focus:outline-none cursor-pointer"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6 text-black"
                    viewBox="0 0 21 22"
                  >
                    <path
                      fill="currentColor"
                      d="M.27 9.992a1.289 1.289 0 0 0-.266.42L0 10.424l.004-.01c.061-.156.151-.3.267-.421Zm10.82-7.578-7.534 7.51h16.032a.989.989 0 0 1 0 1.977H3.555l7.536 7.512a.989.989 0 0 1 0 1.404l-.004.004h-.001a.988.988 0 0 1-1.404 0L.486 11.625a.988.988 0 0 1-.202-.32l-.007-.016a.988.988 0 0 1 0-.75l.007-.017a.989.989 0 0 1 .202-.32L9.68 1.006a.996.996 0 1 1 1.408 1.408Z"
                    ></path>
                  </svg>                </button>
              ) : (
                <button
                  onClick={() => setIsMobileOpen(true)} // You should handle this state correctly
                  className="relative group inline-flex items-center p-2 text-sm text-black rounded-lg hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 text-black hover:bg-gray-200 focus:ring-gray-200 ml-auto lg:ml-0 "
                  aria-controls="mobile-menu-2"
                  aria-expanded="false"
                >
                  <svg
                    class="w-6 h-6"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>  <svg
                    class="hidden w-6 h-6"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>                </button>
              )}
            </div>

            <Link to="/cart" className="ml-4 flex font-bold items-center">
              Order Summary
            </Link>
          </div>
        </div>
      </nav>

      <div className="border-t bg-gray-100 h-auto">
        <div className="flex pt-4 mt-2 bg-white">
          <div className="flex bg-white mt-1 text-sm  ml-4 ">
            Deliver to:

                      {/* <p className="text-black ml-1 font-bold">560058</p> */}

          </div>
          <p className="text-black ml-1 text-sm font-bold mt-1 line-clamp-1 ">15/A 2nd main road 1st Cross Rajiv </p>

           
           </div>

        <div className="flex pb-4 bg-white">
          <div className="flex bg-white mt-1 text-sm w-full ml-4">
            Date & Time:
            <p className="text-black ml-1 font-bold">Sunday 5:30pm</p>
          </div>
          <p className="mr-4 mt-1 text-xs text-red-500">
           
          </p>
        </div>

        <div className="flex mt-3 bg-white border">
          <div className="w-44 h-40 p-2 overflow-hidden">
            <img
              className="w-full h-full object-cover"
              src="https://firebasestorage.googleapis.com/v0/b/splode-events.appspot.com/o/images%2F3299.jpeg?alt=media&token=998d1abc-4d9a-4b81-9cac-467eb004aab0"
              alt="Center Cropped Image"
            />
            <button
              onClick={() => console.log("Remove button clicked")} // Implement your remove logic here
              className="absolute right-4 top-2"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 16 16" class="itemContainer-base-closeIcon"><path fill="#000" fill-rule="evenodd" d="M9.031 8l6.756-6.756a.731.731 0 0 0 0-1.031.732.732 0 0 0-1.031 0L8 6.969 1.244.213a.732.732 0 0 0-1.031 0 .731.731 0 0 0 0 1.03L6.969 8 .213 14.756a.731.731 0 0 0 0 1.031.732.732 0 0 0 1.031 0L8 9.031l6.756 6.756a.732.732 0 0 0 1.031 0 .731.731 0 0 0 0-1.03L9.031 8z"></path></svg>            </button>
          </div>

          <div className="text-xs mt-4">
            <p className="mt-2 font-semibold">
              1st Anniversary Butterfly Decor
            </p>
            <p className="mt-4">
              Purple White Chrome Balloons
              <br />
              Digital Light Included
            </p>

            <div className="flex mt-3">
              <p className="font-bold">₹3,200</p>
              <p className="ml-1 line-through">₹4,200</p>
              <p className="ml-1 text-red-400">30% off</p>
            </div>

            <div className="flex mt-2 space-x-1">
              <svg
                width="15"
                height="15"
                viewBox="0 0 15 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.63639 6.99013C6.84386 7.1976 6.84386 7.53397 6.63639 7.74143L5.7725 8.60533H8.27232C9.21251 8.60533 9.97949 7.84333 9.97949 6.89824C9.97949 5.95914 9.21859 5.19824 8.27949 5.19824H6.89116C6.59776 5.19824 6.35991 4.96039 6.35991 4.66699C6.35991 4.37359 6.59776 4.13574 6.89116 4.13574H8.27949C9.80539 4.13574 11.042 5.37234 11.042 6.89824C11.042 8.43232 9.79722 9.66783 8.27241 9.66783H5.77242L6.63639 10.5318C6.84386 10.7393 6.84386 11.0756 6.63639 11.2831C6.42893 11.4906 6.09256 11.4906 5.88509 11.2831L4.11426 9.51227C4.0417 9.43971 3.99452 9.35138 3.97271 9.25831C3.96352 9.21922 3.95866 9.17846 3.95866 9.13658C3.95866 9.05996 3.97488 8.98713 4.00407 8.92134C4.02519 8.87367 4.05366 8.82847 4.08949 8.78745C4.09828 8.77738 4.10745 8.76764 4.11697 8.75826L5.88509 6.99013C6.09256 6.78267 6.42893 6.78267 6.63639 6.99013Z" fill="#282C3F"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M0.416992 7.50033C0.416992 3.58831 3.58831 0.416992 7.50033 0.416992C11.4123 0.416992 14.5837 3.58831 14.5837 7.50033C14.5837 11.4123 11.4123 14.5837 7.50033 14.5837C3.58831 14.5837 0.416992 11.4123 0.416992 7.50033ZM7.50033 1.47949C4.17511 1.47949 1.47949 4.17511 1.47949 7.50033C1.47949 10.8255 4.17511 13.5212 7.50033 13.5212C10.8255 13.5212 13.5212 10.8255 13.5212 7.50033C13.5212 4.17511 10.8255 1.47949 7.50033 1.47949Z" fill="#282C3F"></path></svg>              </svg>
              <p>Free Cancellation Available</p>
            </div>
          </div>
        </div>

        <div className="bg-gray-100 px-4 py-2 text-xs border-t-2 pt-10">
          <div className="flex font-semibold justify-between">
            <p>Item(s) Total (Inclusive of taxes)</p>
            <p>₹4,000</p>
          </div>
          <div className="flex text-green-500 font-semibold justify-between">
            <p>Discount</p>
            <p>₹2,500</p>
          </div>
          <div className="flex font-semibold justify-between">
            <p>Service Charge</p>
            <p className={service ? "" : "text-green-500"}>{service ? `₹${service}` : `Free`}</p>
          </div>
          <div className="flex font-semibold justify-between">
            <p>Delivery</p>
            <p className={delivery ? "" : "text-green-500"}>{delivery ? `₹${delivery}` : `Free`}</p>
          </div>
          <div className="flex font-semibold justify-between border-t-2 mt-2 pt-2">
            <p>Order Total</p>
            <p>₹7,500</p>
          </div>
        </div>

        <div className="border-t bg-gray-100  bg-white h-auto">
        <div className="flex pt-4 mt-2">
          <div className="flex bg-white mt-1 font-bold text-sm ml-4">
            Payment Method
          </div>
        </div>
        <div className="flex px  mt-1 text-sm ml-4 justify-between bg-white p-2">
          <label>
            <input
              type="radio"
              name="paymentMethod"
              value="payLater"
              checked
              className="text-sm"
            //   onChange={(e) => handlePaymentMethodChange(e.target.value)}
            />
            Pay Later
          </label>

         
        </div>
      
      
      </div>

      <div className=" py-4 bg-gray-100 ">
        <p className="flex mt-1 text-sm ml-4">
            Our Team will contact you regarding the payment and we will contact you to get the advance payement of 20% and will recive the full payment at the date of the event.
          </p>
          

        </div>
    
        <div className="h-48 lg:h-60 bg-white">

        </div>


        <div className="z-40 border-t mt-3 bg-white py-1 bottom-0 sticky px-2 flex justify-center">
          <Link to="/address-list" className="w-full bg-black p-2 text-white rounded text-center">
            Proceed To Book
          </Link>
        </div>



      </div>



    </>
  );
};

export default Paymentpage;
