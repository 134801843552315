import { BrowserRouter, Route, RouterProvider, Routes } from "react-router-dom"
import ProductPage from "./ProductPage"
import App from "./App"
import ProductList from "./ProductList"
import ShoppingBag from "./Components/ShopingBag"
import AdminLogin from "./admin/AdminLogin"
import AdminHome from "./admin/Home"
import AdminProducts from "./admin/Products"
import AdminCategories from "./admin/Categories"
import Orders from "./admin/Orders"
import AdminSliderPage from "./admin/Slider"
import ZipcodePage from "./admin/ZipCode"
import AddAddress from "./Components/AddAddress"
import AddNewAddress from "./Components/AddNewAddress"
import Paymentpage from "./PaymentMethod"
import OrderPlaced from "./Components/OrderPlaced"
import OrderConfirmation from "./Components/OrderConfirmation"
import Order from "./Components/MyOrders"
import ContactUs from "./ContactUs"
import FaqPage from "./FAQ"
import TermsAndConditionsPage from "./TermsCondition"
import BalloonDecorationPage from "./Components/BalloonDecorationPage"

const Router = () => {

    return (

        <BrowserRouter>
            <Routes>

                <Route path="/" element={
                    <div className='bg-black                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  '>
                        <div className='container mx-auto w-96'>
                            <App />
                        </div>
                    </div>
                } ></Route>
                <Route path="/product/:productId" element={<div className='bg-black                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  '>
                    <div className='container mx-auto w-96'>
                        <ProductPage />
                        
                    </div>
                </div>}>

                </Route>
                <Route path="/productList/:list" element={<div className='bg-black                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  '>
                    <div className='container mx-auto w-96'>
                        <ProductList />
                        <BalloonDecorationPage/>

                    </div>
                </div>}></Route>
                
                <Route path="/cart" element={<div className='bg-black                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  '>
                    <div className='container mx-auto w-96'>
                        <ShoppingBag />
                    </div>
                </div>}>
                </Route>
                
                

                <Route path="/payment" element={<div className='bg-black                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  '>
                    <div className='container mx-auto w-96'>
                        <Paymentpage/>
                    </div>
                </div>}>
                </Route>
                <Route path="/orderplaced/:orderId" element={<div className='bg-black                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  '>
                    <div className='container mx-auto w-96'>
                        <OrderPlaced/>
                    </div>
                </div>}>
                </Route>
                <Route
                    path="/address-list"
                    element={
                        <div className="bg-black  flex justify-center items-center">
                            <div className="container h-full mx-auto w-96">
                                <AddAddress />
                            </div>
                        </div>
                    }
                ></Route>
                <Route
                path="/add-address"
                element={
                    <div className="bg-black  flex justify-center items-center">
                            <div className="container h-full mx-auto w-96">
                                <AddNewAddress />
                            </div>
                        </div>
                }
                >
                    
                </Route>

                <Route
                path="/add-address/:id"
                element={
                    <div className="bg-black  flex justify-center items-center">
                            <div className="container h-full mx-auto w-96">
                                <AddNewAddress />
                            </div>
                        </div>
                }
                >
                    
                </Route>

                

                <Route
                path="/myOrders"
                element={
                    <div className="bg-black  flex justify-center items-center">
                    <div className="container h-full mx-auto w-96">
                         <Order />
                    </div>
                </div>
                    
                }
                >
                </Route>


                <Route
                path="/contact"
                element={
                    <div className="bg-black  flex justify-center items-center">
                    <div className="container h-full mx-auto w-96">
                         <ContactUs />
                    </div>
                </div>
                    
                }
                >
                </Route>



                <Route
                path="/faq"
                element={
                    <div className="bg-black  flex justify-center items-center">
                    <div className="container h-full mx-auto w-96">
                         <FaqPage />
                    </div>
                </div>
                    
                }
                >
                </Route>


                <Route
                path="/TermsandCondition"
                element={
                    <div className="bg-black  flex justify-center items-center">
                    <div className="container h-full mx-auto w-96">
                         <TermsAndConditionsPage />
                    </div>
                </div>
                    
                }
                >
                </Route>








                <Route path="/admin" element={<AdminLogin />} />
                <Route path="/admin/home" element={<AdminHome />} />
                <Route path="/admin/products" element={<AdminProducts />} />
                <Route path="/admin/categories" element={<AdminCategories />} />
                <Route path="/admin/orders" element={<Orders />} />
                <Route path="/admin/slider" element={<AdminSliderPage />} />
                <Route path="/admin/zipcode" element={<ZipcodePage />} />








            </Routes>

        </BrowserRouter>
    )
}

export default Router