// TermsAndConditionsPage.js

import React from 'react';
import Header from './Components/Header';

const TermsAndConditionsPage = () => {
  return (
    <div className="bg-gray-200">
      <Header />

      <div className="bg-white p-4">
        <h1 className="text-2xl font-semibold mb-4">Terms and Conditions</h1>

        <div className="mb-4">
          <h2 className="text-lg font-semibold mb-2">1. Booking Services on LareLare.com</h2>
          <p>
            By using LareLare.com to book decoration services provided by Splodeit, you agree to adhere to the terms and conditions outlined in this document. Booking is available for various occasions, including birthdays, anniversaries, baby showers, and more.
          </p>
        </div>

        <div className="mb-4">
          <h2 className="text-lg font-semibold mb-2">2. Service Availability</h2>
          <p>
            Splodeit's decoration services through LareLare.com are currently limited to the Bangalore area. Users outside this region may not be eligible for booking. Availability is subject to change, and users can check service availability by entering their ZIP code during the booking process.
          </p>
        </div>

        <div className="mb-4">
          <h2 className="text-lg font-semibold mb-2">3. Customization Options</h2>
          <p>
            Users have the option to customize decorations for their events. Contact Splodeit's team through LareLare.com to discuss and create a personalized decor plan that suits the theme and requirements of the event.
          </p>
        </div>

        <div className="mb-4">
          <h2 className="text-lg font-semibold mb-2">4. Cancellation Policy</h2>
          <p>
            Splodeit's cancellation policy allows for easy cancellation up to 48 hours before the scheduled event. A complete refund will be issued if the cancellation occurs within this timeframe. Refer to the cancellation policy for more details.
          </p>
        </div>

        <div className="mb-4">
          <h2 className="text-lg font-semibold mb-2">5. Payment Terms</h2>
          <p>
            Payment for decoration services must be made in accordance with the payment terms specified during the booking process. Failure to adhere to the payment terms may result in the cancellation of the booking.
          </p>
        </div>

        <div className="mb-4">
          <h2 className="text-lg font-semibold mb-2">6. Liability and Damages</h2>
          <p>
            Splodeit is not liable for any damages or injuries that may occur during or as a result of the decoration services. Users are responsible for ensuring the safety of their guests and property during the event.
          </p>
        </div>

        {/* Add more terms and conditions as needed */}

      </div>
    </div>
  );
};

export default TermsAndConditionsPage;
