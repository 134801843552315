import React, { useState, useEffect } from 'react';
import { useSwipeable } from 'react-swipeable';

const ProductImageSlider = ({ images }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);



  const handleImageClick = (index) => {
    setCurrentImageIndex(index);
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    },
    onSwipedRight: () => {
      setCurrentImageIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
    },
  });

  return (
    <div {...handlers} className="relative">
      <img
        src={images[currentImageIndex]}
        alt={`Slide ${currentImageIndex + 1}`}
        className="w-full "
      />

      <div className="flex justify-center mt-1 space-x-1 absolute bottom-4 left-1/2 transform -translate-x-1/2">
        {images.map((image, index) => (
          <button
            key={index}
            type="button"
            className={`w-3 h-3 rounded-full ${
              index === currentImageIndex ? 'bg-red-800' : 'bg-gray-300'
            }`}
            style={{ width: '6px', height: '6px' }}
            onClick={() => handleImageClick(index)}
            aria-current={index === currentImageIndex}
            aria-label={`Slide ${index + 1}`}
          ></button>
        ))}
      </div>
    </div>
  );
};

export default ProductImageSlider;
