import React, { useState, useEffect } from "react";
import SidePanel from "./Components/SidePanel";
import AdminHeader from "./Components/Header";
import AddProductModal from "./Components/AddProductsModel";
import AddCategory from "./Components/AddCategory";
import EditProductModal from "./Components/EditProductModel";
import { initializeApp } from "firebase/app";
import { getFirestore, collection, query, getDocs, where, deleteDoc, doc } from "firebase/firestore";
import { getAuth, onAuthStateChanged } from 'firebase/auth';

const AdminProducts = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [productsPerPage] = useState(10);
  const [showAddModal, setshowAddModal] = useState(false);
  const [showAddCategory, setShowAddCategory] = useState(false);
  const [products, setProducts] = useState([]);
  const [showEditModal, setshowEditModal] = useState(false);
  const [currentProduct, setCurrentProduct] = useState({});
  const [loggedIn, setLoggedIn] = useState(false);
  const [currentProductKey, setCurrentProductKey] = useState('');
  const [loading, setLoading] = useState(true); // To handle loading state
  const [category, setCategory] = useState('all'); // State for category filter

  useEffect(() => {
    const checkAdminStatus = (user) => {
      if (user && user.email === 'manuvini29@gmail.com') {
        setLoggedIn(true);
      } else {
        window.location.href = '/admin';
      }
    };

    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      checkAdminStatus(user);
    });

    return () => unsubscribe();
  }, []);

  const db = getFirestore();

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const productsCollection = collection(db, 'products');
        let q;

        // Create a query to filter products by category
        if (category !== 'all') {
          q = query(productsCollection, where('category', '==', category));
        } else {
          q = productsCollection; // Get all products when category is "all"
        }

        // Execute the query and get the documents
        const querySnapshot = await getDocs(q);

        // Convert the documents to an array of product objects
        const productData = querySnapshot.docs.map((doc) => {
          return {
            ...doc.data(),
            key: doc.id, // Use doc.id to uniquely identify each product
          };
        });

        // Format the product data as needed
        const formattedProducts = productData.map((p) => ({
          id: p.key,
          name: p.name,
          category: p.category,
          price: Number(p.price),
          originalPrice: Number(p.originalPrice),
          rating: p.rating,
          description: p.description, // Assuming description is an array and you want the first element
          downloadUrls: p.downloadUrls || [], // Assuming downloadUrls is an array
        }));

        setProducts(formattedProducts);

        console.log(formattedProducts);

        setLoading(false);
      } catch (error) {
        console.error('Error fetching products:', error);
        setLoading(false);
      }
    };

    fetchProducts();
  }, [category, db]); // Reload products when category changes

  const deleteProduct = (productId) => {
    const productRef = doc(db, "products", productId);
    deleteDoc(productRef)
      .then(() => {
        console.log("Product deleted successfully");
        setProducts((prevProducts) => prevProducts.filter((product) => product.id !== productId));
      })
      .catch((error) => {
        console.log("Error deleting product:", error.message);
      });
  };

  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = products.slice(indexOfFirstProduct, indexOfLastProduct);

  const handleAddCategory = (category) => {
    setShowAddCategory(false);
  };

  const handleCancelAddCategory = () => {
    setShowAddCategory(false);
  };

  const totalPages = Math.ceil(products.length / productsPerPage);

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleEditClick = async (productId) => {
    let productData = products.find(obj => obj.id === productId);
    setCurrentProduct(productData);
    setCurrentProductKey(productId);
    setshowEditModal(true);
  };

  const handleDeleteClick = (productId) => {
    console.log(`Deleting product with ID ${productId}`);
    deleteProduct(productId);
  };

  const handleAddProduct = () => {
    setshowAddModal(true);
  };

  if (!loggedIn) {
    return null; // or return a loading/spinner component
  }

  return (
    <div className="flex h-screen">
      <SidePanel />
      <div className="flex-1 ml-56">
        <AdminHeader />
        <div className="p-6">
          <div className="flex justify-between mb-4">
            <h2 className="text-2xl font-bold">Products</h2>
            {showAddModal && (
              <AddProductModal
                showModal={showAddModal}
                setShowModal={setshowAddModal}
              ></AddProductModal>
            )}
            {showEditModal && (
              <EditProductModal
                showModal={showEditModal}
                setShowModal={setshowEditModal}
                product={currentProduct}
                productId={currentProductKey}
              ></EditProductModal>
            )}
            <div>
              <button
                onClick={handleAddProduct}
                className="px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600 mr-2"
              >
                Add Product
              </button>
              <button
                onClick={() => setShowAddCategory(true)}
                className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
              >
                Add Category
              </button>
            </div>
            {showAddCategory && (
              <AddCategory
                showModal={showAddCategory}
                setShowModal={setShowAddCategory}
              />
            )}
          </div>
          <p className="mb-4">Total Products: {products.length}</p>
          {loading ? (
            <p>Loading...</p>
          ) : (
            <table className="w-full border-collapse">
              <thead>
                <tr>
                  <th className="px-6 py-3 bg-gray-200 text-gray-700 border">ID</th>
                  <th className="px-6 py-3 bg-gray-200 text-gray-700 border">Name</th>
                  <th className="px-6 py-3 bg-gray-200 text-gray-700 border">Price(₹)</th>
                  <th className="px-6 py-3 bg-gray-200 text-gray-700 border">Category</th>
                  <th className="px-6 py-3 bg-gray-200 text-gray-700 border">Actions</th>
                </tr>
              </thead>
              <tbody>
                {currentProducts.map((product) => (
                  <tr key={product.id}>
                    <td className="px-6 py-3 border">{product.id}</td>
                    <td className="px-6 py-3 border">{product.name}</td>
                    <td className="px-6 py-3 border">{product.price}</td>
                    <td className="px-6 py-3 border">{product.category}</td>
                    <td className="px-6 py-3 border w-1/6">
                      <button
                        className="px-4 py-2 bg-yellow-500 text-white rounded-md hover:bg-yellow-600 mr-2"
                        onClick={() => handleEditClick(product.id)}
                      >
                        Edit
                      </button>
                      <button
                        className="px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600"
                        onClick={() => handleDeleteClick(product.id)}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
          {totalPages > 1 && (
            <ul className="flex mt-4">
              {Array.from({ length: totalPages }).map((_, index) => (
                <li
                  key={index}
                  className={`px-3 py-1 border ${
                    currentPage === index + 1
                      ? "bg-gray-200"
                      : "hover:bg-gray-200"
                  }`}
                  onClick={() => handlePageClick(index + 1)}
                >
                  {index + 1}
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminProducts;
