import React, { useState } from "react";

const ZipCodeChange = ({ currentZipCode, onZipCodeChange, isOpen, onClose }) => {
  const [newZipCode, setNewZipCode] = useState(currentZipCode);

  const handleZipCodeChange = (e) => {
    setNewZipCode(e.target.value);
  };

  const handleSubmit = () => {
    onZipCodeChange(newZipCode);
    onClose();
  };

  return (
    <>
      {isOpen && (
        <div
          className="fixed z-50 inset-0 bg-gray-800 opacity-50"
          onClick={onClose}
        ></div>
      )}
      <div
        className={`fixed w-96 z-50 bottom-0 transform transition-transform duration-300 ${
          isOpen ? "translate-y-0" : "translate-y-full"
        } ease-in-out bg-white h-32 p-4`}
      >
        <div className="mb-4">
            <div className="py-3 font-semibold text-sm ">Enter Venue ZipCode</div>

        <div className="flex space-x-2">

        <input
            type="text"
            maxLength={6}
            placeholder="Enter new zip code"
            value={newZipCode}
            onChange={handleZipCodeChange}
            className="border border-gray-300 px-2 py-1 rounded w-full"
          />
          <button
          onClick={handleSubmit}
          className="bg-green-400 text-white px-3 py-1 rounded cursor-pointer"
        >
          Check
        </button>

            
        </div>
         
        </div>
        
      </div>
    </>
  );
};

export default ZipCodeChange;
