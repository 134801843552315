import React, { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

const OrderPlaced = () => {
  const { orderId } = useParams();

  const navigate = useNavigate();
  const delivery = 5565;
  const service = 7654;
  const cartItems = [1];

  const setIsMobileOpen = false; // It should be set to true or false as needed
  const handleLogout = () => {
    // Implement your logout logic here
  };
  const isLogedInOptions = false; // It should be set to true or false as needed
  const isDropdownVisible = false; // It should be set to true or false as needed
  const isLogedIn = false; // It should be set to true or false as needed

  const [isZipCodeChangeOpen, setIsZipCodeChangeOpen] = useState(false);
  const [zipCode, setZipCode] = useState("560058");

  const handleZipCodeChange = (newZipCode) => {
    setZipCode(newZipCode);
  };

  const [isDateTimeChangeOpen, setIsDateTimeChangeOpen] = useState(false);
  const [dateTime, setDateTime] = useState();
  const handleDateTimeChange = (newDateTime) => {
    const date = new Date(newDateTime);
    const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    const formattedTime = `${formattedHours}:${formattedMinutes} ${ampm}`;
  
    // Combine the formatted date and time
    const formattedDateTime = `${formattedDate} ${formattedTime}`;
  
    setDateTime(formattedDateTime);
  };
  

  return (
    <>
      <nav className="sticky top-0 z-40 border-gray-600 bg-white shadow">
        <div className="flex flex-wrap justify-between items-center mx-auto max-w-screen-xl p-2">
          <div className="flex items-center md:w-auto w-full">
            <div className="flex items-center">
              {true ? (
                <button
                  onClick={() => navigate(-1)}
                  className="focus:outline-none cursor-pointer"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6 text-black"
                    viewBox="0 0 21 22"
                  >
                    <path
                      fill="currentColor"
                      d="M.27 9.992a1.289 1.289 0 0 0-.266.42L0 10.424l.004-.01c.061-.156.151-.3.267-.421Zm10.82-7.578-7.534 7.51h16.032a.989.989 0 0 1 0 1.977H3.555l7.536 7.512a.989.989 0 0 1 0 1.404l-.004.004h-.001a.988.988 0 0 1-1.404 0L.486 11.625a.988.988 0 0 1-.202-.32l-.007-.016a.988.988 0 0 1 0-.75l.007-.017a.989.989 0 0 1 .202-.32L9.68 1.006a.996.996 0 1 1 1.408 1.408Z"
                    ></path>
                  </svg>                </button>
              ) : (
                <button
                  onClick={() => setIsMobileOpen(true)} // You should handle this state correctly
                  className="relative group inline-flex items-center p-2 text-sm text-black rounded-lg hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 text-black hover:bg-gray-200 focus:ring-gray-200 ml-auto lg:ml-0 "
                  aria-controls="mobile-menu-2"
                  aria-expanded="false"
                >
                  <svg
                    class="w-6 h-6"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>  <svg
                    class="hidden w-6 h-6"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>                </button>
              )}
            </div>

            <Link to="/cart" className="ml-4 flex font-bold items-center">
              Order Placed
            </Link>
          </div>
        </div>
      </nav>

      <div className="h-screen flex items-center justify-center">
        <div className="bg-white h-screen flex flex-col items-center justify-center">
          <div className="flex">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 50 50"
              width="100px"
              height="100px"
            >
              <path d="M43.171,10.925L24.085,33.446l-9.667-9.015l1.363-1.463l8.134,7.585L41.861,9.378C37.657,4.844,31.656,2,25,2 C12.317,2,2,12.317,2,25s10.317,23,23,23s23-10.317,23-23C48,19.701,46.194,14.818,43.171,10.925z" />
            </svg>
          </div>

          <div className="flex p-5">
            Order Placed Successful, Order Id {orderId}
          </div>

          <div className="flex p-5">
            Our Event Manager Will Contact You In Next 24 Hours And Will Guide You With Next Process.
          </div>

          <Link to={"/"} className="rounded flex p-2 bg-black text-white font-bold">
            Continue Shopping
          </Link>
        </div>
      </div>
      

     

        



    </>
  );
};

export default OrderPlaced;
