import React, { useState } from "react";

const AddAddressOverlay = ({ onClose, onSave }) => {
  const [newAddress, setNewAddress] = useState({
    name: "",
    address: "",
    Phone: "",
    pincode: "560058",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewAddress((prevAddress) => ({
      ...prevAddress,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    // Validate the new address fields if needed
    // Then, pass the new address to the onSave function
    onSave(newAddress);

    // Close the overlay
    onClose();
  };

  return (
    <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-50">
      <div className="bg-white w-96 p-6 rounded-md">
        <h2 className="text-lg font-semibold mb-4">Add New Address</h2>
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">
            Name
          </label>
          <input
            type="text"
            name="name"
            value={newAddress.name}
            onChange={handleInputChange}
            className="border rounded-md px-3 py-2 w-full"
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">
            Address
          </label>
          <input
            type="text"
            name="address"
            value={newAddress.address}
            onChange={handleInputChange}
            className="border rounded-md px-3 py-2 w-full"
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">Phone</label>
          <input
            type="text"
            name="city"
            value={newAddress.city}
            onChange={handleInputChange}
            className="border rounded-md px-3 py-2 w-full"
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">Pincode</label>
          <input
            type="text"
            name="pincode"
            disabled
            value={newAddress.pincode}
            onChange={handleInputChange}
            className="border rounded-md px-3 py-2 w-full"
          />
        </div>
        <div className="flex justify-end">
          <button
            onClick={handleSubmit}
            className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500"
          >
            Save
          </button>
          <button
            onClick={onClose}
            className="ml-2 border border-gray-300 px-4 py-2 rounded-md hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddAddressOverlay;
