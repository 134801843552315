import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { collection, getDocs, getFirestore, query, where } from 'firebase/firestore';

const HorizontalProductsView = ({ category }) => {
    const [products, setProducts] = useState([]);

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const q = query(collection(getFirestore(), 'products'), where('category', '==', category));
                const querySnapshot = await getDocs(q);
                
                const productsArr = querySnapshot.docs.map(doc => {
                    const data = doc.data();
                    return {
                        id: doc.id,
                        category: data.category,
                        price: data.price,
                        originalPrice: data.originalPrice,
                        name: data.name,
                        description: data.description ,
                        imageUrl: [data.downloadUrls[0]]
                    };
                });

                setProducts(productsArr);
            } catch (error) {
                console.error("Error fetching products: ", error);
            }
        };

        fetchProducts();
    }, [category]);

    return (
        <div className="bg-white p-2 mt-4">
            <p className="text-black text-sm font-semibold mt-3 ml-4">Similar Decors</p>
            <div className="overflow-x-scroll flex space-x-4 p-2">
                {products.map((product, index) => (
                    <Link
                        to={`/product/${product.id}`}
                        key={index}
                        className="flex-shrink-0 w-32"
                    >
                        <img
                            src={product.imageUrl[0]}
                            alt={product.name}
                            className="w-32 h-32 object-cover rounded-md"
                        />
                        <p className="text-xs mt-2 truncate text-center">{product.name}</p>
                        <div className="px-2 flex">
                            <p className="text-xs pr-1 font-semibold">
                                ₹{product.price.toLocaleString()}
                            </p>
                            <p className="text-xs pr-1 text-gray-400 line-through">
                                ₹{product.originalPrice.toLocaleString()}
                            </p>
                            <p className="text-xs text-orange-400 font-semibold">
                                ({(((product.originalPrice - product.price) / product.originalPrice) * 100).toFixed(2)}%)
                            </p>
                        </div>
                    </Link>
                ))}
            </div>
        </div>
    );
};

export default HorizontalProductsView;
