// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth"; // Add this line to import the authentication functions
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyB-g3o_kFR0U1pUtxoCrCfcMeJKlhDOVRk",
  authDomain: "splode-events.firebaseapp.com",
  projectId: "splode-events",
  storageBucket: "splode-events.appspot.com",
  messagingSenderId: "231947875727",
  appId: "1:231947875727:web:05c9068d3c17c1ee13c7fa",
  measurementId: "G-QNCL6V5Y5H"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app); // Add this line to get the authentication object

export { app, analytics, auth }; // Export the authentication object along with the other exports
export default firebaseConfig;
