import { useState } from "react";
import { getFirestore, updateDoc, deleteDoc, doc } from "firebase/firestore";

const OrderModal = ({ orderData, closeModal }) => {
  console.log(orderData);
  const [selectedStatus, setSelectedStatus] = useState(orderData.orderStatus);

  const handleStatusChange = (e) => {
    const newStatus = e.target.value;
    setSelectedStatus(newStatus);
    updateStatusInFirestore(newStatus);
  };

  const handleDeleteOrder = async () => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this order?"
    );
    if (confirmDelete) {
      try {
        const db = getFirestore();
        const orderRef = doc(
          db,
          "orders",
          orderData.orderYearMonth,
          'order',
          orderData.id
        );
        await deleteDoc(orderRef);
        console.log("Order deleted successfully");
        closeModal();
      } catch (error) {
        console.error("Error deleting order:", error);
      }
    }
  };

  const updateStatusInFirestore = async (newStatus) => {
    const db = getFirestore();
    const orderRef = doc(
      db,
      "orders",
      orderData.orderYearMonth,
        'order',
      orderData.id
    );

    try {
      await updateDoc(orderRef, { orderStatus: newStatus });
      console.log("Status updated successfully in Firestore");
    } catch (error) {
      console.error("Error updating status in Firestore:", error);
    }
  };

  const renderTableCell = (label, value) => {
    return (
      <tr>
        <td className="py-2 pr-4">{label}</td>
        <td className="py-2">{value || "NA"}</td>
      </tr>
    );
  };
  //  const orderDate = new Date(orderData.orderTimestamp)

  return (
    <div className="fixed inset-0 w-full flex items-center justify-center z-50">
      <div className="fixed inset-0 bg-gray-600 bg-opacity-75"></div>
      <div className="bg-white rounded-lg p-8 max-w-screen-lg relative">
        <h2 className="text-2xl font-bold mb-6">Order Details</h2>
        <div className="overflow-y-auto max-h-96">
          <table className="w-full mb-6">
            <tbody>
              {renderTableCell("Name", orderData.selectedAddress.name)}
              {renderTableCell("Phone Number", orderData.selectedAddress.mobileNumber)}
              {renderTableCell("Alternate Number", orderData.selectedAddress.alternateNumber)}
              {renderTableCell("Scheduled Date Time", convertTimestampToDate(orderData.dateTime))}
              {renderTableCell("Ordered Date Time", convertTimestampToDate(orderData.createdAt))}
              {renderTableCell(
                "Address",
                ` ${orderData.selectedAddress.address} ${orderData.selectedAddress.locality} ${orderData.selectedAddress.city} ${orderData.selectedAddress.pincode} ${orderData.selectedAddress.state}}`
              )}
              {renderTableCell("User Email", orderData.userId)}
              {renderTableCell("Order ID", orderData.orderNumber)}
              {renderTableCell("Service Charge", orderData.serviceCharge)}
              {renderTableCell("Delivery Charge", orderData.deliveryCharge)}

              {renderTableCell(
                "Selected Payment Method",
                orderData.PaymentMethod
              )}

            

              {/* Products Data */}
              {/* {orderData.productsData.length > 0 && (
                <tr>
                  <th className="py-4 pr-4">Product Name</th>
                  <th className="py-4 pr-4" colSpan="2">
                    Product Image
                  </th>
                  <th className="py-4 pr-4">Quantity</th>
                  <th className="py-4 pr-4">Price</th>
                 
                </tr>
              )} */}
               {/* {orderData.productsData.map((element, index) => (
                <tr key={index}>
                  <td className="py-2 pr-4">{element.productName}</td>
                  <td className="py-2 pr-4" colSpan="2">
                    {element.productImage ? (
                      <img
                        src={element.productImage}
                        alt="Product"
                        className="w-24 h-24 justify-center"
                      />
                    ) : (
                      "NA"
                    )}
                  </td>
                  <td className="py-2 pr-4">{element.productQuantity}</td>
                  <td className="py-2 pr-4">{element.productPrice}</td>
                  

                  
                </tr>
              ))}  */}

              {/* Status */}
              <tr>
                <td className="py-2 pr-4">Status</td>
                <td className="py-2">
                  <select value={selectedStatus} onChange={handleStatusChange}>
                    <option value="Pending">Pending</option>
                    <option value="Decoration Confirmed">Decoration Confirmed</option>
                    <option value="Decorator Assigned">Decorator Assigned</option>
                    <option value="Decoration Started">Decoration Started</option>
                    <option value="Decorator Returned">Decorator Returned</option>
                    <option value="Decoration Completed/Payment Pending">Decoration Completed/Payment Pending</option>
                    <option value="Decoration Completed/Payment Completed">Decoration Completed/Payment Completed</option>
                    <option value="Canceled">Canceled</option>
                  </select>
                </td>
              </tr>

              {/* Total Price */}
              <tr>
                <td className="py-2 pr-4">Total Price</td>
                <td className="py-2">{orderData.total || "NA"}</td>
              </tr>

              <td className="py-2 pr-4">{orderData.decorationId}</td>
                  <td className="py-2 pr-4" colSpan="2">
                    {orderData.imageUrl ? (
                      <img
                        src={orderData.imageUrl}
                        alt="Product"
                        className="w-24 h-24 justify-center"
                      />
                    ) : (
                      "NA"
                    )}

                </td>
            </tbody>
          </table>
        </div>
        <div className="flex justify-between">
          <button
            className="bg-red-500 mt-4 hover:bg-red-600 text-white px-6 py-3 border rounded"
            onClick={handleDeleteOrder}
          >
            Delete Order
          </button>
          <button
            className="bg-blue-500 mt-4 hover:bg-blue-600 text-white px-6 py-3 border rounded"
            onClick={closeModal}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};


function convertTimestampToDate(timestamp) {
  // Convert seconds and nanoseconds to milliseconds
  const milliseconds = timestamp.seconds * 1000 + timestamp.nanoseconds / 1e6;

  // Create a new Date object using the milliseconds
  const date = new Date(milliseconds);

  // Extract date components
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed, so add 1
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  // Format the date as "YYYY-MM-DD HH:mm:ss"
  const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

  return formattedDate;
}

export default OrderModal;
