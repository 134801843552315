import React, { useState, useEffect } from "react";
import SidePanel from "./Components/SidePanel";
import AdminHeader from "./Components/Header";
import AddCategory from "./Components/AddCategory";

import { initializeApp } from "firebase/app";
import { getDoc } from "firebase/firestore";
import { getFirestore, collection, query, onSnapshot, addDoc, deleteDoc, doc } from "firebase/firestore";
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import EditCategoryModal from "./Components/EditCategory";

const AdminCategories = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [categoriesPerPage] = useState(10);
  const [showAddModal, setshowAddModal] = useState(false);
  const [showEditModal, setshowEditModal] = useState(false);
  const [categories, setCategories] = useState([]);
  const [loggedIn, setLoggedIn] = useState(false);
  const [currentCategory, setCurrentCategory] = useState({});
  const [currentCategoryKey, setCurrentCategoryKey] = useState('');
  const [showAddCategory,setShowAddCategory] = useState("");

  useEffect(() => {
    const checkAdminStatus = (user) => {
      if (user && user.email === 'manuvini29@gmail.com') {
        setLoggedIn(true);
      } else {
        window.location.href = '/admin';
      }
    };

    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      checkAdminStatus(user);
    });

    return () => unsubscribe();
  }, []);

  const db = getFirestore();

  useEffect(() => {
    const q = query(collection(db, "categories"));
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const categoriesArr = [];
      var id = 0;
      snapshot.forEach((doc) => {
        id++;
        const { name, imageUrl } = doc.data();
        categoriesArr.push({ id: id, key: doc.id, name, imageUrl });
      });
      setCategories(categoriesArr);
    });

    return () => unsubscribe();
  }, []);

  const deleteCategory = (categoryId) => {
    const categoryRef = doc(db, "categories", categoryId);
    deleteDoc(categoryRef)
      .then(() => {
        console.log("Category deleted successfully");
      })
      .catch((error) => {
        console.log("Error deleting category:", error.message);
      });
  };

  const indexOfLastCategory = currentPage * categoriesPerPage;
  const indexOfFirstCategory = indexOfLastCategory - categoriesPerPage;
  const currentCategories = categories.slice(indexOfFirstCategory, indexOfLastCategory);

  const handleCancelAddCategory = () => {
    setShowAddCategory(false);
  };

  const totalPages = Math.ceil(categories.length / categoriesPerPage);

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleEditClick = async (categoryId) => {
    try {
      const categoryDocRef = doc(db, "categories", categoryId);
      const categorySnapshot = await getDoc(categoryDocRef);
      if (categorySnapshot.exists()) {
        const categoryData = categorySnapshot.data();
        setCurrentCategory(categoryData);
        setCurrentCategoryKey(categoryId);
        setshowEditModal(true);
      } else {
        console.log("Category does not exist");
      }
    } catch (error) {
      console.error("Error fetching category:", error);
    }
    console.log(`Editing category with ID ${categoryId}`);
  };

  if (!loggedIn) {
    return null; // or return a loading/spinner component
  }

  return (
    <div className="flex h-screen">
      <SidePanel />
      <div className="flex-1 ml-56">
        <AdminHeader />
        <div className="p-6">
          <div className="flex justify-between mb-4">
            <h2 className="text-2xl font-bold">Categories</h2>
            {showAddModal&&(<AddCategory showModal={showAddModal} setShowModal={setshowAddModal}></AddCategory>)}
            {showEditModal&&(<EditCategoryModal showModal={showEditModal} setShowModal={setshowEditModal} category={currentCategory} categoryId={currentCategoryKey}></EditCategoryModal>)}
            <div>
              <button onClick={() => setshowAddModal(true)} className="px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600 mr-2">
                Add Category
              </button>
            </div>
          </div>
          <p className="mb-4">Total Categories: {categories.length}</p>
          <table className="w-full border-collapse">
            <thead>
              <tr>
                <th className="px-6 py-3 bg-gray-200 text-gray-700 border">ID</th>
                <th className="px-6 py-3 bg-gray-200 text-gray-700 border">Name</th>
                <th className="px-6 py-3 bg-gray-200 text-gray-700 border">Image</th>
                <th className="px-6 py-3 bg-gray-200 text-gray-700 border">Actions</th>
              </tr>
            </thead>
            <tbody>
              {currentCategories.map((category) => (
                <tr key={category.id}>
                  <td className="px-6 py-3 border">{category.id}</td>
                  <td className="px-6 py-3 border">{category.name}</td>
                  <td className="px-6 py-3 border">
                    <img src={category.imageUrl} alt={category.name} className="h-10 w-10" />
                  </td>
                  <td className="px-6 py-3 border w-1/6">
                    <button
                      className="px-4 py-2 bg-yellow-500 text-white rounded-md hover:bg-yellow-600 mr-2"
                      onClick={() => handleEditClick(category.key)}
                    >
                      Edit
                    </button>
                    <button
                      className="px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600"
                      onClick={() => deleteCategory(category.key)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {totalPages > 1 && (
            <ul className="flex mt-4">
              {Array.from({ length: totalPages }).map((_, index) => (
                <li
                  key={index}
                  className={`px-3 py-1 border ${
                    currentPage === index + 1
                      ? "bg-gray-200"
                      : "hover:bg-gray-200"
                  }`}
                  onClick={() => handlePageClick(index + 1)}
                >
                  {index + 1}
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminCategories;
