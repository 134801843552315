import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getFirestore, collection, query, getDocs } from 'firebase/firestore';
import banner from '../img/valentineBanner.png';

const SidePanel = ({ isOpen, setIsMobileOpen }) => {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const db = getFirestore();
        const q = query(collection(db, 'categories'));
        const querySnapshot = await getDocs(q);
        const categoriesData = [];
        querySnapshot.forEach((doc) => {
          const { name, imageUrl } = doc.data();
          categoriesData.push({ id: doc.id, name, imageUrl });
        });
        setCategories(categoriesData);
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    fetchCategories();
  }, []);

  return (
    <>
      {isOpen && (
        <div
          className="fixed z-50 inset-0 bg-black opacity-50"
          onClick={() => setIsMobileOpen(false)}
        ></div>
      )}
      <div
        className={`fixed inset-y-0 z-50 left-0 transform ${
          isOpen ? 'translate-x-0' : '-translate-x-full'
        } transition duration-300 ease-in-out bg-gray-300 w-64 overflow-y-auto`}
      >
        <div className="h-screen flex flex-col">
          <img src={banner} className="img-responsive preLoad loaded" alt="Banner" />

          {/* Categories */}
          {categories.map((category) => (
            <Link to={`/productList/${category.name}`} key={category.id} className="flex text-sm bg-white">
              <p className="font-semibold p-4">{category.name}</p>
              <p className="absolute text-gray-400 right-0 p-4">{">"}</p>
            </Link>
          ))}

          <div className="flex text-sm border-b bg-white">
            <p className="font-semibold p-4">Other Parties</p>
            <p className="absolute text-gray-400  right-0  p-4">{">"}</p>
          </div>

          <Link to={'/contact'} className="flex text-sm text-gray-400  bg-white">
            <p className=" p-4">Contact Us</p>
          </Link>

          <Link to={'/faq'} className="flex text-sm  text-gray-400  bg-white">
            <p className=" p-4">FAQ's</p>
          </Link>

          <Link to={'/TermsandCondition'} className="flex text-sm  text-gray-400 bg-white">
            <p className=" p-4">Legal</p>
          </Link>

          <img src={banner} className="img-responsive preLoad loaded" alt="Banner" />
        </div>
      </div>
    </>
  );
};

export default SidePanel;
