import logo from './logo.svg';
import './App.css';
import Header from './Components/Header';
import CategoryScroll from './Components/CatComp';
import GridComponent from './Components/Grid';
import SidePanel from './Components/Side';
import AuthSlide from './Components/BottomSlide';
import { useState } from 'react';
import firebaseConfig from './firebase/firebaseConfig';
import { initializeApp } from 'firebase/app';
import BalloonDecorationPage from './Components/BalloonDecorationPage';

function App() {

  const app = initializeApp(firebaseConfig);


  


  return (
   <>
   <Header></Header>
   <CategoryScroll></CategoryScroll>
   <GridComponent category={"all"}/>

   <BalloonDecorationPage/>

   
   
   </>
  );
}

export default App;
