import React, { useState } from 'react';
import { getAuth, signInWithPhoneNumber, PhoneAuthProvider } from 'firebase/auth';
import { GoogleLoginButton } from '../Components/common/GoogleBtn';
import {  signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import axios from "axios";
import valen from "../img/valentineSale.png"
import { useEffect } from 'react';
import { auth } from '../firebase/firebaseConfig';

const AuthSlide = ({ isOpen , setAuthSlide }) => {

 


    const [phoneNumber, setPhoneNumber] = useState('');
    const [verificationCode, setVerificationCode] = useState('');
    const [verificationId, setVerificationId] = useState(null);
    const [error, setError] = useState(null);
  

    const toggleSlide = () => {
        setAuthSlide(!isOpen);
    };

    useEffect(() => {
        // Add an observer to watch for changes in the authentication state
        const unsubscribe = auth.onAuthStateChanged((authUser) => {
          if (authUser) {
            // User is logged in
            setAuthSlide(false)
          } 
        });
    
        // Cleanup the observer when the component is unmounted
        return () => unsubscribe();
      }, []); 

    // const handlePhoneNumberChange = (e) => {
    //     setPhoneNumber(e.target.value);
    //   };
    
    //   const handleSendVerificationCode = async () => {

    //     const auth = getAuth();
    //      console.log(auth)
    //     signInWithPhoneNumber(auth,`+91`+phoneNumber)
    //         .then((confirmationResult) => {
    //             console.log(confirmationResult)
    //           // SMS sent. Prompt user to type the code from the message, then sign the
    //           // user in with confirmationResult.confirm(code).
    //           window.confirmationResult = confirmationResult;
    //           // ...
    //         }).catch((error) => {
    //             console.log(error)
    //           // Error; SMS not sent
    //           // ...
    //         });
        
    //   };
    
    //   const handleVerifyCode = async () => {
    //     try {
    //       const auth = getAuth();
    //       const credential = PhoneAuthProvider.credential(verificationId, verificationCode);
    //       await auth.signInWithCredential(credential);
    //       console.log('Phone number verified successfully!');
    //       // You can perform additional actions here after successful verification
    //     } catch (error) {
    //       setError('Error verifying code');
    //       console.error('Error verifying code:', error.message);
    //     }
    //   };


    const handleLogin = () => {
        console.log("Hello");
        const auth = getAuth();
        const provider = new GoogleAuthProvider();
    
        // Sign in with Google popup
        signInWithPopup(auth, provider)
          .then((result) => {
            const user = result.user;
           // Data to be sent in the POST request
        const postData = {
            accountId: user.uid,
            accountName: user.displayName,
            userEmail: user.email,
            // Add other fields as needed
        };

        // Make a POST request to the specified URL
        axios.post('https://larelare.azurewebsites.net/account/', postData)
            .then((response) => {
                console.log('Account created successfully:', response.data);
                // Redirect to admin home after successful login
            })
            .catch((error) => {
                console.error('Failed to create account:', error);
            });
          })
          .catch((error) => {
            console.error('Login failed:', error);
          });
      };
    


    return (
        <>
            {isOpen && (
                <div
                    className="fixed z-50 inset-0  bg-gray-800 opacity-50"
                    onClick={() => setAuthSlide(false)}
                ></div>
            )}
            <div className={`fixed w-96 z-50 bottom-0 transform ${isOpen ? 'translate-y-0' : 'translate-y-full'} transition duration-300 ease-in-out bg-white h-80 `}>
                <img
                    src={valen}
                >
                </img>
                <button
                    className='absolute  right-2 top-2'
                    onClick={() => setAuthSlide(false)}
                >
                    <svg width="24" height="24" viewBox="0 0 24 24" fill=""><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z" opacity="0.05"></path><path fill="#FFFFFF" d="M12.967 12L19.3 5.666a.685.685 0 000-.967.686.686 0 00-.967 0L12 11.033 5.666 4.7a.686.686 0 00-.967 0 .685.685 0 000 .967L11.033 12 4.7 18.334a.685.685 0 000 .967.686.686 0 00.967 0L12 12.967l6.334 6.334a.686.686 0 00.967 0 .685.685 0 000-.967L12.967 12z"></path></g></svg>
                </button>


                <div className=' flex   mr-5 ml-5 mt-5 py-2'>
                           {/* Additional login form elements go here */}
          <GoogleLoginButton onClick={handleLogin}  ></GoogleLoginButton>

                </div>

             
{/*
                <div className='flex ml-4 mt-5 space-x-1'>
                    <h1 className='font-bold text-xl text-gray-800'>Login </h1>
                    <h1 className=' text-xl text-gray-800'>or </h1>
                    <h1 className='font-bold text-xl text-gray-800'>Signup </h1>
                </div>

                <div className='border flex border-gray-600  mr-5 ml-5 mt-5 py-2'>

                    <div className='text-black text-sm px-3 border-gray-600 border-r'>
                        +91
                    </div>
                    <input className='mx-3 text-sm border-none  focus:outline-none' style={{ caretColor: 'red' }}
                     maxLength={10}
                     onChange={handlePhoneNumberChange}
                     />
                </div>
                <p className='ml-5  text-red-400' style={{ fontSize: '9px' }}>{error}</p>
  <div className='ml-5 mr-5 mt-5 '>
                    <button className='rounded p-2 bg-red-600 text-white block w-full'

                    >
                        Continue
                    </button>     
               </div>`
*/}

                <p className='text-gray-400 mt-5 text-xs ml-5 mr-5' style={{ fontSize: "11px" }}>
                    By continuing, I agree to the Terms of Use & Privacy Policy
                </p>

              

               <p className='text-gray-400 mt-3 text-xs ml-5 mr-5' style={{ fontSize: "11px" }}>
                Have trouble logging in? Get help
                </p>

            </div>
        </>
    );
};

export default AuthSlide;
