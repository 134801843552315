import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import ZipCodeChange from "./Zipcode";
import DateTimeChange from "./DateTimeChange";
import CustomDateTimePicker from "./DateTimeChange";
import { useParams } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from "../firebase/firebaseConfig";
import axios from "axios";
import ErrorNotification from "./common/ErrorNotification";
import Header from "./Header";
import AuthSlide from "./BottomSlide";
import { getFirestore,Timestamp , deleteDoc,collection, updateDoc ,query, getDoc,addDoc,where,doc,getDocs } from 'firebase/firestore';
import { v4 as uuidv4 } from 'uuid'; // Import UUID library




const ShoppingBag = () => {
  const navigate = useNavigate();
         const cartDocId = 'cart123'; // Replace with actual cart document ID

  const [errorMessage,setErrorMessage] = useState("");
  const [showError, setShowError] = useState(false);
  const [Transpoatation, setTransportation] = useState(0);
  const [service,setService] = useState(0); 
  const cartItems = [1];
  const [cartData, setCartData] = useState(null);
  const [productData, setProductData] = useState(null);

  const [images, setImage] = useState([]);
  const [description, setDescription] = useState([]);
  const [ total , setTotal ] = useState(0);
  const [user, loading] = useAuthState(auth);
  const [setProductId, productId] = useState();
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [addressId,setAddressId] = useState();
  const [address,setAddress] = useState();
  const [paymentMethod, setPaymentMethod] = useState("payLater");
  const [isProcessing,setIsProcessing] = useState(false);
  const [convertCartToOrder,setConvertCartToOrder] = useState(false);



  const setIsMobileOpen = false; // It should be set to true or false as needed
  const handleLogout = () => {
    // Implement your logout logic here
  };
  const isLogedInOptions = false; // It should be set to true or false as needed
  const isDropdownVisible = false; // It should be set to true or false as needed
  const isLogedIn = false; // It should be set to true or false as needed

  const [isZipCodeChangeOpen, setIsZipCodeChangeOpen] = useState(false);
  const [zipCode, setZipCode] = useState("560058");



  const handlePaymentMethodChange = (method) => {
    setPaymentMethod(method);
  };

  const handleZipCodeChange = (newZipCode) => {
    if(!address){
      setZipCode(newZipCode)
    }
  };


  useEffect(() => {
    if (user) {
      const fetchCartData = async () => {
        try {
          const db = getFirestore();
          const cartCollectionRef = collection(db, 'users', user.uid, 'cart');

          // Query to get the first document in the cart collection
          const cartQuery = query(cartCollectionRef);
          const querySnapshot = await getDocs(cartQuery);

          if (!querySnapshot.empty) {
            const firstDoc = querySnapshot.docs[0];
            const cartRecord = firstDoc.data();

            console.log(cartRecord);
            setCartData(cartRecord);

            // Handling date and time
            const inputDate = cartRecord.dateTime.toDate(); // Convert Firestore timestamp to JavaScript Date object

            const day = inputDate.getDate().toString().padStart(2, '0');
            const month = (inputDate.getMonth() + 1).toString().padStart(2, '0');
            const year = inputDate.getFullYear();

            const hours = inputDate.getHours().toString().padStart(2, '0');
            const minutes = inputDate.getMinutes().toString().padStart(2, '0');

            const formattedDateString = `${day}/${month}/${year}, ${hours}:${minutes} ${inputDate.getHours() >= 12 ? 'PM' : 'AM'}`;

            // Set data according to the specified fields
            setDateTime(formattedDateString);
            setService(cartRecord.ServiceCharge__c || 0);
            setTransportation(cartRecord.Tranportation__c || 0);

            // Extract and set address details
            const add = cartRecord.selectedAddress || {};
            setAddress(add);
            setZipCode(add.pincode || '');
            setSelectedAddress(
              `${add.name || ''}, ${add.address || ''} ${add.locality || ''} ${add.city || ''} ${add.pincode || ''}`
            );
          } else {
            console.error('No cart documents found!');
            setProductData(null);
            setCartData(null);
          }
        } catch (error) {
          console.error('Error retrieving cart data:', error.message);
        }
      };

      fetchCartData();
    }
  }, [user]);




 

  useEffect(() => {
    window.scrollTo(0, 0);

    const fetchProductData = async () => {
      if (cartData) {
        try {
          const db = getFirestore();
          const productDocRef = doc(db, 'products', cartData.decorationId);
          const productDoc = await getDoc(productDocRef);

          if (productDoc.exists()) {
            const product = productDoc.data();

            const productData = {
              id: product.Id,
              category: product.category,
              price: product.price,
              originalPrice: product.originalPrice,
              name: product.name,
              description: product.description,
              imageUrl: product.downloadUrls[0]
            };

            console.log(productData);
            setDescription(productData.description);
            setImage(productData.imageUrl);
            setProductData(productData);
            setTotal(product.price);


          } else {
            console.error('No product data found!');
          }
        } catch (error) {
          console.error('Error fetching product data:', error.message);
        }
      }
    };

    fetchProductData();
  }, [cartData]);


  

  const [isDateTimeChangeOpen, setIsDateTimeChangeOpen] = useState(false);
  const [dateTime, setDateTime] = useState();

  const handleDateTimeChange = async (newDateTime) => {
    try {
      const db = getFirestore();
      const date = new Date(newDateTime);
  
      // Assuming `cartDocId` is known or retrieved from your cart data
      const cartDocRef = doc(db, 'users', user.uid, 'cart', cartDocId);
  
      // Update the document with the new date and time
      await updateDoc(cartDocRef, {
        dateTime: Timestamp.fromDate(date) // Convert Date to Firestore Timestamp
      });
  
      console.log('Date updated in Firestore successfully');
      setDateTime(date); // Update local state with the new date
    } catch (error) {
      console.error('Error updating date in Firestore:', error.message);
    }
  };
  


  // Example function to convert cart to order using Axios


  useEffect(() => {
    const convertCartToOrder1 = async () => {
      try {
        // Check if cartData.dateTime is a valid Timestamp
        if (!(cartData.dateTime instanceof Timestamp)) {
          throw new Error('Invalid dateTime format');
        }

        // Create yearMonth string for Firestore collection path
        const date = cartData.dateTime.toDate(); // Convert Timestamp to Date for formatting
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const monthYear = `${year}${month}`;

        // Generate a unique order number
        const orderNumber = await generateUniqueOrderNumber();

        // Prepare order data for Firestore
        const firestoreOrderData = {
          ...cartData,
          dateTime: cartData.dateTime, // Use Timestamp directly
          timestamp: Timestamp.now(), // Add current timestamp
          total,
          customerEmail: user.email,
          imageUrl: productData.imageUrl ,
          userId:user.uid,
          PaymentMethod: 'paylater',
          orderStatus: 'pending',
          orderNumber // Include unique order number
        };

        // Reference to the Firestore orders collection
        const ordersCollectionRef = collection(getFirestore(), 'orders', monthYear, 'order');

        // Push the order data to Firestore
        const orderDocRef = await addDoc(ordersCollectionRef, firestoreOrderData);

        console.log('Order pushed to Firestore successfully');
        
        // Reference to the cart document
        const cartDocRef = doc(getFirestore(), 'users', user.uid, 'cart', cartDocId);

        // Delete the cart document
        await deleteDoc(cartDocRef);

        console.log('Cart document deleted successfully');

        // Redirect to order placed page
        navigate('/orderPlaced/' + orderNumber); 
        
      } catch (error) {
        console.error('Error converting cart to order:', error.message);
      }
    };

    if (cartData && total !== undefined && convertCartToOrder) {
      convertCartToOrder1();
    }
  }, [cartData, total, navigate, convertCartToOrder]);



  const generateUniqueOrderNumber = async () => {
    const db = getFirestore();
    let orderNumber;
    let isUnique = false;

    while (!isUnique) {
      // Generate a random five-digit number
      const randomNumber = Math.floor(10000 + Math.random() * 90000); // Between 10000 and 99999
      orderNumber = `LA-${randomNumber}`;

      // Check if the number is unique
      const ordersCollectionRef = collection(db, 'orders');
      const q = query(ordersCollectionRef, where('orderNumber', '==', orderNumber));
      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        isUnique = true; // Unique if no matching documents are found
      }
    }

    return orderNumber;
  };

  const handlePlaceOrder = () => {

    if(!selectedAddress || !dateTime ){
      setErrorMessage("Please select Address/Time");
      setShowError(true);
    }else{
      setConvertCartToOrder(true)
      setIsProcessing(true);

    }
  

  }
  // if(!cartData){
  //   return(



    
  //     <div className="animate-pulse">
  //     <div className="bg-gray-200 h-full">
  //       <Header back={true} />


  //       <div className="border-t bg-gray-100 h-auto animate-pulse">
  //     {/* Loading state placeholders */}
  //     <div className="flex pt-4 mt-2 bg-white">
  //       <div className="flex bg-white mt-1 text-sm w-full ml-4">
  //         Deliver to:
  //         <div className="bg-gray-300 h-4 w-20 ml-1"></div>
  //       </div>
  //       <div className="mr-4 mt-1 text-xs text-red-500">
  //         <button className="cursor-pointer text-blue-500 bg-gray-300"></button>
  //       </div>
  //     </div>

  //     <div className="flex pb-4 bg-white">
  //       <div className="flex bg-white mt-1 text-sm w-full ml-4">
  //         Date & Time:
  //         <div className="bg-gray-300 h-4 w-20 ml-1"></div>
  //       </div>
  //       <div className="mr-4 mt-1 text-xs text-red-500">
  //         <button className="cursor-pointer text-blue-500 bg-gray-300"></button>
  //       </div>
  //     </div>

  //     {/* New section for address selection */}
  //     <div className="flex bg-white">
  //       <div className="flex bg-white mt-1 text-sm w-full ml-4">
  //         Select Address:
  //       </div>
  //       <p className="mr-4 mt-1 text-xs text-red-500">
  //         <Link to="/address-list" className="cursor-pointer text-blue-500 bg-gray-300"></Link>
  //       </p>
  //     </div>
  //     <div className="flex pb-4 bg-white">
  //       <p className="flex bg-white mt-1 font-bold text-xs line-clamp-2 w-full ml-4 bg-gray-300"></p>
  //     </div>
  //   </div>




  //   <div className="flex mt-3 bg-white border animate-pulse">
  //     <div className="w-44 h-40 p-2 overflow-hidden">
  //       <div className="bg-gray-300 h-full w-full"></div>
  //       <div className="absolute right-4 top-2">
  //         <div className="bg-gray-300 w-6 h-6"></div>
  //       </div>
  //     </div>

  //     <div className="text-xs mt-4 w-full ml-4">
  //       <div className="bg-gray-300 h-4 w-2/3 mb-2"></div>
  //       <div className="bg-gray-300 h-4 w-1/2 mt-2"></div>
  //       <div className="bg-gray-300 h-4 w-3/4 mt-2"></div>
  //       <div className="bg-gray-300 h-4 w-2/3 mt-2"></div>
  //     </div>

  //     <div className="flex mt-3">
  //       <div className="font-bold bg-gray-300 h-4 w-1/4"></div>
  //       <div className="ml-1 line-through bg-gray-300 h-4 w-1/4"></div>
  //       <div className="ml-1 text-red-400 bg-gray-300 h-4 w-1/4"></div>
  //     </div>

  //     <div className="text-xs mt-4 w-full ml-4">
  //       <div className="bg-gray-300 h-4 w-2/3 mb-2"></div>
  //       <div className="bg-gray-300 h-4 w-1/2 mt-2"></div>
  //       <div className="bg-gray-300 h-4 w-3/4 mt-2"></div>
  //       <div className="bg-gray-300 h-4 w-2/3 mt-2"></div>
  //     </div>

  //     <div className="flex mt-3">
  //       <div className="font-bold bg-gray-300 h-4 w-1/4"></div>
  //       <div className="ml-1 line-through bg-gray-300 h-4 w-1/4"></div>
  //       <div className="ml-1 text-red-400 bg-gray-300 h-4 w-1/4"></div>
  //     </div>

      
  //   </div>





  //   <>
  //     <div className="flex pb-4 border-t-2 bg-gray-100 animate-pulse">
  //       <div className="flex bg-gray-100 mt-4 text-xs w-full ml-4">
  //         <span className="font-bold mr-2 border-b-2 bg-gray-300 w-16"></span>
  //       </div>
  //     </div>

  //     <div className="flex pb-4 bg-gray-210 animate-pulse">
  //       <div className="flex bg-gray-100 text-xs w-full ml-4">
  //         <div className="ml-2">
  //           <label className="inline-flex items-center">
  //             <div className="form-radio h-4 w-4 text-gray-600 bg-gray-300"></div>
  //             <span className="ml-2 text-xs bg-gray-300 w-12"></span>
  //           </label>
  //           <label className="inline-flex items-center ml-4">
  //             <div className="form-radio h-4 w-4 text-gray-600 bg-gray-300"></div>
  //             <span className="ml-2 text-xs bg-gray-300 w-12"></span>
  //           </label>
  //         </div>
  //       </div>
  //     </div>
  //   </>




  //   <div className="flex p-3 space-x-2 items-center">
  //             <div className="animate-pulse bg-gray-300 h-4 w-1/4"></div>
  //             <p className="animate-pulse bg-gray-300 h-4 w-3/4 ml-2"></p>
  //           </div>

  //           <div className="text-gray-400 mt-5 text-sm">
  //             <p className="animate-pulse bg-gray-300 h-4 w-3/4"></p>
  //             <p className="animate-pulse bg-gray-300 h-4 w-3/4 mt-2"></p>
  //             <p className="animate-pulse bg-gray-300 h-4 w-3/4 mt-2"></p>
  //           </div>

       
       
  //           <div className="flex p-3 space-x-2 items-center">
  //             <div className="animate-pulse bg-gray-300 h-4 w-1/4"></div>
  //             <p className="animate-pulse bg-gray-300 h-4 w-3/4 ml-2"></p>
  //           </div>

  //           <div className="text-gray-400 mt-5 text-sm">
  //             <p className="animate-pulse bg-gray-300 h-4 w-3/4"></p>
  //             <p className="animate-pulse bg-gray-300 h-4 w-3/4 mt-2"></p>
  //             <p className="animate-pulse bg-gray-300 h-4 w-3/4 mt-2"></p>
  //           </div>


  //           <div className="flex p-3 space-x-2 items-center">
  //             <div className="animate-pulse bg-gray-300 h-4 w-1/4"></div>
  //             <p className="animate-pulse bg-gray-300 h-4 w-3/4 ml-2"></p>
  //           </div>

  //           <div className="text-gray-400 mt-5 text-sm">
  //             <p className="animate-pulse bg-gray-300 h-4 w-3/4"></p>
  //             <p className="animate-pulse bg-gray-300 h-4 w-3/4 mt-2"></p>
  //             <p className="animate-pulse bg-gray-300 h-4 w-3/4 mt-2"></p>
  //           </div>



  //       </div>
  //       </div>
        
          
         
      
  //   )
  // }

 

  return (
    <>


      <nav className="sticky top-0 z-40 border-gray-600 bg-white shadow">
        <div className="flex flex-wrap justify-between items-center mx-auto max-w-screen-xl p-2">
          <div className="flex items-center md:w-auto w-full">
            <div className="flex items-center">
              {true ? (
                <button
                  onClick={() => navigate(-1)}
                  className="focus:outline-none cursor-pointer"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6 text-black"
                    viewBox="0 0 21 22"
                  >
                    <path
                      fill="currentColor"
                      d="M.27 9.992a1.289 1.289 0 0 0-.266.42L0 10.424l.004-.01c.061-.156.151-.3.267-.421Zm10.82-7.578-7.534 7.51h16.032a.989.989 0 0 1 0 1.977H3.555l7.536 7.512a.989.989 0 0 1 0 1.404l-.004.004h-.001a.988.988 0 0 1-1.404 0L.486 11.625a.988.988 0 0 1-.202-.32l-.007-.016a.988.988 0 0 1 0-.75l.007-.017a.989.989 0 0 1 .202-.32L9.68 1.006a.996.996 0 1 1 1.408 1.408Z"
                    ></path>
                  </svg>                </button>
              ) : (
                <button
                  onClick={() => setIsMobileOpen(true)} // You should handle this state correctly
                  className="relative group inline-flex items-center p-2 text-sm text-black rounded-lg hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 text-black hover:bg-gray-200 focus:ring-gray-200 ml-auto lg:ml-0 "
                  aria-controls="mobile-menu-2"
                  aria-expanded="false"
                >
                  <svg
                    class="w-6 h-6"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>  <svg
                    class="hidden w-6 h-6"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>                </button>
              )}
            </div>

            <Link to="/cart" className="ml-4 flex font-bold items-center">
              Cart
            </Link>
          </div>
        </div>
      </nav>

      <ErrorNotification errorMessage={errorMessage} show={showError} setShow={setShowError}></ErrorNotification>

{
  productData ?


  <div className="border-t bg-gray-100 h-auto">
  <div className="flex pt-4 mt-2 bg-white">
    <div className="flex bg-white mt-1 text-sm w-full ml-4">
      Deliver to:
      <p className="text-black ml-1 font-bold">{zipCode}</p>
    </div>
    <p className="mr-4 mt-1 text-xs text-red-500">
      {address?<></>:
       <button
       onClick={() => setIsZipCodeChangeOpen(true)} // Open DateTimeChange component
       className="cursor-pointer text-blue-500"
     >
       CHANGE
     </button>
      }
     
    </p>        </div>

  <div className="flex pb-4 bg-white">
    <div className="flex bg-white mt-1 text-sm w-full ml-4">
      Date & Time:
      <p className="text-black ml-1 font-bold">{dateTime}</p>
    </div>
    <p className="mr-4 mt-1 text-xs text-red-500">
      <button
        onClick={() => setIsDateTimeChangeOpen(true)} // Open DateTimeChange component
        className="cursor-pointer text-blue-500"
      >
        CHANGE
      </button>
    </p>
  </div>


  {/* New section for address selection */}
<div className="flex  bg-white">
  <div className="flex bg-white mt-1 text-sm w-full ml-4">
    Select Address:

  </div>
  <p className="mr-4 mt-1 text-xs text-red-500">
      <Link
        to="/address-list"
        className="cursor-pointer text-blue-500"
      >
        SELECT 
      </Link>
    </p>
</div>
<div className="flex pb-4 bg-white">
<p className="flex bg-white mt-1 font-bold text-xs line-clamp-2 w-full ml-4">
  {selectedAddress}
</p>
</div>

  <div className="flex mt-3 bg-white border">
    <div className="w-44 h-40 p-2 overflow-hidden">
      <img
        className="w-full h-full object-cover"
        src={productData.imageUrl}
        alt="Center Cropped Image"
      />
      <button
        onClick={() => console.log("Remove button clicked")} // Implement your remove logic here
        className="absolute right-4 top-2"
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 16 16" class="itemContainer-base-closeIcon"><path fill="#000" fill-rule="evenodd" d="M9.031 8l6.756-6.756a.731.731 0 0 0 0-1.031.732.732 0 0 0-1.031 0L8 6.969 1.244.213a.732.732 0 0 0-1.031 0 .731.731 0 0 0 0 1.03L6.969 8 .213 14.756a.731.731 0 0 0 0 1.031.732.732 0 0 0 1.031 0L8 9.031l6.756 6.756a.732.732 0 0 0 1.031 0 .731.731 0 0 0 0-1.03L9.031 8z"></path></svg>            </button>
    </div>

    <div className="text-xs mt-4">
      <p className="mt-2 font-semibold">
        {productData.Name}
      </p>
      {/* <p className="mt-4">
        Purple White Chrome Balloons
        <br />
        Digital Light Included
      </p> */}
      {description.map((p, index) => (
        <p className="mt-4" key={index}>{p}</p>
      ))}

      <div className="flex mt-3">
        <p className="font-bold">₹{productData.price}</p>
        <p className="ml-1 line-through"> ₹{productData.originalPrice}</p>
        <p className="ml-1 text-red-400"> ({(((productData.originalPrice - productData.price) / productData.originalPrice) * 100).toFixed(2)}%)</p>
      </div>

      <div className="flex mt-2 space-x-1">
        <svg
          width="15"
          height="15"
          viewBox="0 0 15 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.63639 6.99013C6.84386 7.1976 6.84386 7.53397 6.63639 7.74143L5.7725 8.60533H8.27232C9.21251 8.60533 9.97949 7.84333 9.97949 6.89824C9.97949 5.95914 9.21859 5.19824 8.27949 5.19824H6.89116C6.59776 5.19824 6.35991 4.96039 6.35991 4.66699C6.35991 4.37359 6.59776 4.13574 6.89116 4.13574H8.27949C9.80539 4.13574 11.042 5.37234 11.042 6.89824C11.042 8.43232 9.79722 9.66783 8.27241 9.66783H5.77242L6.63639 10.5318C6.84386 10.7393 6.84386 11.0756 6.63639 11.2831C6.42893 11.4906 6.09256 11.4906 5.88509 11.2831L4.11426 9.51227C4.0417 9.43971 3.99452 9.35138 3.97271 9.25831C3.96352 9.21922 3.95866 9.17846 3.95866 9.13658C3.95866 9.05996 3.97488 8.98713 4.00407 8.92134C4.02519 8.87367 4.05366 8.82847 4.08949 8.78745C4.09828 8.77738 4.10745 8.76764 4.11697 8.75826L5.88509 6.99013C6.09256 6.78267 6.42893 6.78267 6.63639 6.99013Z" fill="#282C3F"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M0.416992 7.50033C0.416992 3.58831 3.58831 0.416992 7.50033 0.416992C11.4123 0.416992 14.5837 3.58831 14.5837 7.50033C14.5837 11.4123 11.4123 14.5837 7.50033 14.5837C3.58831 14.5837 0.416992 11.4123 0.416992 7.50033ZM7.50033 1.47949C4.17511 1.47949 1.47949 4.17511 1.47949 7.50033C1.47949 10.8255 4.17511 13.5212 7.50033 13.5212C10.8255 13.5212 13.5212 10.8255 13.5212 7.50033C13.5212 4.17511 10.8255 1.47949 7.50033 1.47949Z" fill="#282C3F"></path></svg>              </svg>
        <p>Free Cancellation Available</p>
      </div>
    </div>
  </div>

  <div className="bg-gray-100 px-4 py-2 text-xs border-t-2 pt-5">
    <div className="flex font-semibold justify-between">
      <p>Item(s) Total (Inclusive of taxes)</p>
      <p>₹{productData.price}</p>
    </div>
    <div className="flex text-green-500 font-semibold justify-between">
      <p>Discount</p>
      <p>({(((productData.originalPrice - productData.price) / productData.originalPrice) * 100).toFixed(2)}%)</p>
    </div>
    <div className="flex font-semibold justify-between">
      <p>Service Charge</p>
      <p className={service ? "" : "text-green-500"}>{service ? `₹${service}` : `Free`}</p>
    </div>
    <div className="flex font-semibold justify-between">
      <p>Transporataion</p>
      <p className={Transpoatation ? "" : "text-green-500"}>{Transpoatation ? `₹${Transpoatation}` : `Free`}</p>
    </div>
    <div className="flex font-semibold justify-between border-t-2 mt-2 pt-2">
      <p>Order Total</p>
      <p>₹{total}</p>
    </div>
  </div>


  <div className="flex pb-4 border-t-2 bg-gray-100">
<div className="flex bg-gray-100 mt-4 text-xs  w-full ml-4">
<span className="font-bold mr-2 border-b-2">Payment Method:</span>
</div>
</div>

<div className="flex pb-4 bg-gray-210">
<div className="flex bg-gray-100 text-xs w-full ml-4">
<div className="ml-2">
<label className="inline-flex items-center">
  <input
    type="radio"
    value="payLater"
    checked={paymentMethod === "payLater"}
    onChange={() => handlePaymentMethodChange("payLater")}
    className="form-radio h-4 w-4 text-gray-600"
  />
  <span className="ml-2 text-xs">Pay Later</span>
</label>
{/* <label className="inline-flex items-center ml-4">
  <input
    type="radio"
    value="payOnline"
    checked={paymentMethod === "payOnline"}
    onChange={() => handlePaymentMethodChange("payOnline")}
    className="form-radio h-4 w-4 text-gray-600"
  />
  <span className="ml-2 text-xs">Pay Online</span>
</label> */}
</div>
</div>
</div>




  <div className="h-48 lg:h-60 bg-white">

  </div>




  <div className="z-40 border-t mt-3 bg-white py-1 bottom-0 sticky px-2 flex justify-center">
    




      <div className="z-40 border-t mt-3 bg-white py-1 bottom-0 sticky px-2 flex">
          <button
            onClick={() => handlePlaceOrder()}
            className={`w-full p-2 text-white rounded justify-center bg-${isProcessing ? 'black' : 'black'} ${isProcessing ? 'cursor-not-allowed' : 'cursor-pointer'}`}
            disabled={isProcessing}
          >
          
            {isProcessing ? 'Processing...' : 'Proceed To Book'}
          </button>
        </div>



    
  </div>



</div>  

:


<div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
  <div className=" p-10">
    Cart is empty
  </div>
  {/* Additional content, if any */}
</div>






}
     


      <ZipCodeChange
        currentZipCode={zipCode}
        onZipCodeChange={handleZipCodeChange}
        isOpen={isZipCodeChangeOpen}
        onClose={() => setIsZipCodeChangeOpen(false)} // Close ZipCodeChange component
      />


      <DateTimeChange
        currentDateTime={dateTime}
        onDateTimeChange={handleDateTimeChange}
        isOpen={isDateTimeChangeOpen}
        onClose={() => setIsDateTimeChangeOpen(false)} // Close DateTimeChange component
      />
    </>
  );
};

export default ShoppingBag;
