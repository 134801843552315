// FaqPage.js

import React from 'react';
import Header from './Components/Header';

const FaqPage = () => {
  return (
    <div className="bg-gray-200">
      <Header />

      <div className="bg-white p-4">
        <h1 className="text-2xl font-semibold mb-4">Frequently Asked Questions</h1>

        <div className="mb-4">
          <h2 className="text-lg font-semibold mb-2">1. Where is Splodeit located?</h2>
          <p>
            Splodeit, the registered company behind LareLare.com, is based in Bangalore. Our decoration services are currently limited to the Bangalore area.
          </p>
        </div>

        <div className="mb-4">
          <h2 className="text-lg font-semibold mb-2">2. What types of decorations do Splodeit offer through LareLare.com?</h2>
          <p>
            Splodeit, available on LareLare.com, offers a variety of decorations for different occasions, including weddings, parties, and corporate events. Our decorations include but are not limited to floral arrangements, lighting setups, and themed decor.
          </p>
        </div>

        <div className="mb-4">
          <h2 className="text-lg font-semibold mb-2">3. Can I customize the decorations for my event through LareLare.com?</h2>
          <p>
            Yes, through LareLare.com, you can access Splodeit's customization options for decorations. Contact our team, and we'll work with you to create a personalized decor plan that suits your event's theme and requirements.
          </p>
        </div>

        <div className="mb-4">
          <h2 className="text-lg font-semibold mb-2">4. How can I check if Splodeit's services on LareLare.com are available in my area?</h2>
          <p>
            You can use LareLare.com to check the availability of Splodeit's services in your area. Simply enter your ZIP code during the booking process, and we'll let you know if our services are available in your location.
          </p>
        </div>

        <div className="mb-4">
          <h2 className="text-lg font-semibold mb-2">5. What is Splodeit's cancellation policy through LareLare.com?</h2>
          <p>
            Through LareLare.com, Splodeit's cancellation policy allows for easy cancellation up to 48 hours before the scheduled event. You'll receive a complete refund if you cancel within this timeframe.
          </p>
        </div>

        {/* Add more FAQs as needed */}

      </div>
    </div>
  );
};

export default FaqPage;
